
import { Vue, Component } from 'nuxt-property-decorator'

import { encodeRoute } from '~/utils/encodeRoute'

/**
 * 404 Error Page
 */
@Component({})
export default class FourOFour extends Vue {
  get loginUrl(): string {
    if (!this.$route.name?.startsWith('provider-owner')) {
      return '/login'
    }

    let next = ''
    try {
      next = encodeRoute(this.$route)
    } catch (error) {
      this.$logErrorAndToast(error as Error)
    }

    return `/login?next=${next}`
  }
}
