
import { Component, mixins, Watch } from 'nuxt-property-decorator'
import { MobileNav } from '@/components/Layout'
import AuthMixin from '@/mixins/authMixin'
import PortalMixin from '@/mixins/portalMixin'

import ControlPanelBaseMixin from '~/mixins/control-panel/ControlPanelBaseMixin'
import { stripTrailingSlash } from '~/utils/string'
import { ControlPanelRouteT } from '~/types/control-panel'

/**
 * Layout for control panel
 */
@Component({
  components: {
    MobileNav
  },
  head: {
    bodyAttrs: {
      class: 'antialiased stroke-2 min-h-screen bg-ink-400'
    }
  },
  meta: {
    auth: { strict: true }
  },
  middleware: ['restrictControlPanelAccess']
})
export default class ControlPanelLayout extends mixins(
  AuthMixin,
  PortalMixin,
  ControlPanelBaseMixin
) {
  showFeedbackModal = false
  reRenderBreadcrumbs = false

  /**
   * Fetch hook to get data about the org
   * @return {Promise<void>}
   */
  async fetch(): Promise<void> {
    await this.getOrgBaseData()
  }

  get activeRouteInfo(): ControlPanelRouteT | undefined {
    if (this.isSubroute) {
      const subRoutes = this.CONTROL_PANELS.find(
        ({ to }) => to === stripTrailingSlash(this.parentRoute ?? '')
      )?.subroutes
      if (subRoutes) {
        return (
          subRoutes.find(({ to }) => to === stripTrailingSlash(this.$route.path)) ||
          this.CONTROL_PANELS.find(({ to }) => to === stripTrailingSlash(this.parentRoute))
        )
      }
    }
    return this.CONTROL_PANELS.find(({ to }) => to === stripTrailingSlash(this.$route.path))
  }

  get pageSubroutes(): ControlPanelRouteT[] {
    return (
      this.CONTROL_PANELS.find(({ to }) => to === stripTrailingSlash(this.parentRoute ?? ''))
        ?.subroutes || []
    )
  }

  // ! Hack to force re-render breadcrumbs till they support dynamic updates
  @Watch('$route.path')
  updateBreadcrumbs() {
    this.reRenderBreadcrumbs = !this.reRenderBreadcrumbs
  }
}
