// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/errors/404/stars-bg-mobile.webp");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/errors/404/stars-bg-mobile.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/errors/404/stars-bg-desktop.webp");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/images/errors/404/stars-bg-desktop.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/images/errors/404/foreground-desktop.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".four-o-four[data-v-3969c64c]{background:#01010f;background-size:cover;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-image:-webkit-image-set(url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") type(\"image/webp\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") type(\"image/png\"));background-image:image-set(url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") type(\"image/webp\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") type(\"image/png\"))}@media screen and (min-width:1024px){.four-o-four[data-v-3969c64c]{background:#01010f;background-size:cover;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-image:-webkit-image-set(url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") type(\"image/webp\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") type(\"image/png\"));background-image:image-set(url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") type(\"image/webp\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") type(\"image/png\"))}}.foreground[data-v-3969c64c]{width:1263px;height:256px;background-position:50%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");background-repeat:no-repeat}.smoke[data-v-3969c64c]{margin-right:-30rem;bottom:5.5rem;height:85px}.text-container[data-v-3969c64c]{background:rgba(92,114,207,.2);-webkit-backdrop-filter:blur(4px);backdrop-filter:blur(4px);border-radius:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
