
import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { NuxtError } from '@nuxt/types'

import { formatDate } from '~/utils/date'
import { encodeRoute } from '~/utils/encodeRoute'
import { RequestAccessErrorVariant } from '~/types/errorTypes'

@Component({
  methods: {
    formatDate
  }
})
export default class ErrorLayout extends Vue {
  @Prop({ required: true })
  error: NuxtError

  get loginUrl(): string {
    let next = ''
    try {
      next = encodeRoute(this.$route)
    } catch (error) {
      this.$logErrorAndToast(error as Error)
    }

    return `/login?next=${next}`
  }

  get areSeatsFull(): boolean {
    if (this.error.message)
      return this.error.message.indexOf('You have exhausted all the seats') > 0
    return false
  }

  get requestAccessFields() {
    const errorList: RequestAccessErrorVariant[] = ['request-access', 'insufficient-permissions']

    //? Error message for 401 will always be of type `error-message: <message>`
    //? e.g. `request-access: You do not have access to this repository.`
    const errorMessageType = this.error.message?.split(':')[0] ?? ''

    return {
      visible:
        this.$config.onPrem &&
        this.error.statusCode === 401 &&
        errorList.includes(errorMessageType as RequestAccessErrorVariant),
      copytext: this.error.message?.replace(`${errorMessageType}:`, ''),
      variant: errorMessageType
    }
  }
}
