
import { Component, Watch, mixins } from 'nuxt-property-decorator'
import ControlPanelBaseMixin from '~/mixins/control-panel/ControlPanelBaseMixin'

import { ControlPanelRouteT } from '~/types/control-panel'
import { containsElement } from '~/utils/ui'

@Component({})
export default class Sidebar extends mixins(ControlPanelBaseMixin) {
  public isCollapsed = false
  public collapsedSidebar = false
  public isOpen = false

  created() {
    this.isCollapsed = Boolean(this.$nuxt.$cookies.get('ui-state-sidebar-collapsed'))
    this.collapsedSidebar = Boolean(this.$nuxt.$cookies.get('ui-state-sidebar-collapsed'))
  }

  mounted() {
    this.$root.$on('ui:show-sidebar-menu', () => {
      this.isCollapsed = false
      this.collapsedSidebar = false
      this.isOpen = true
    })

    this.$root.$on('ui:toggle-sidebar-menu', () => {
      this.isCollapsed = !this.isCollapsed
      this.collapsedSidebar = !this.collapsedSidebar
      this.isOpen = !this.isOpen
    })
  }

  beforeDestroy() {
    this.$root.$off('ui:show-sidebar-menu')
    this.$root.$off('ui:toggle-sidebar-menu')
  }

  get mainControlPanelMenus(): ControlPanelRouteT[] {
    return this.CONTROL_PANELS.filter(
      ({ title }) => !this.secondaryMenuList.includes(this.getPageTitle(title))
    )
  }

  get secondaryControlPanelMenus(): ControlPanelRouteT[] {
    return this.CONTROL_PANELS.filter(({ title }) =>
      this.secondaryMenuList.includes(this.getPageTitle(title))
    )
  }

  toggleSidebarCollapse(): void {
    const newVal = !this.isCollapsed

    if (newVal) {
      this.isCollapsed = !this.isCollapsed
      this.collapsedSidebar = !this.collapsedSidebar
    } else {
      this.collapsedSidebar = !this.collapsedSidebar
      setTimeout(() => {
        this.isCollapsed = !this.isCollapsed
      }, 100)
    }

    this.$nuxt.$cookies.set('ui-state-sidebar-collapsed', newVal)
  }

  closeMenu(event: Event): void {
    const target = event.target as HTMLElement
    const toggleButton = document.getElementById('mobile-menu-toggle')
    if (!toggleButton) {
      this.isOpen = false
    } else if (!containsElement(toggleButton, target) && target.id !== 'mobile-menu-toggle') {
      this.isOpen = false
    }
  }

  public isActive(params: string): boolean {
    return this.$route.name?.startsWith(params) || false
  }

  toggleSidebar(): void {
    this.isOpen = !this.isOpen
  }

  @Watch('isOpen')
  disableScroll(newIsOpen: boolean) {
    if (newIsOpen && process.client) {
      document.body.classList.add('no-scroll')
    } else if (process.client) {
      document.body.classList.remove('no-scroll')
    }
  }
}
