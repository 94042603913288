
import { Component, mixins, Watch } from 'nuxt-property-decorator'
import { LoggedOutSidebar, MobileNav } from '@/components/Layout'
import { Sidebar } from '@/components/Layout/Sidebar'
import AuthMixin from '@/mixins/authMixin'
import PortalMixin from '@/mixins/portalMixin'
import ActiveUserMixin from '~/mixins/activeUserMixin'

/**
 * Active user layout
 */
@Component({
  components: {
    Sidebar,
    LoggedOutSidebar,
    MobileNav
  },
  meta: {
    auth: {
      strict: true,
      redirectToLogin: true
    }
  },
  head: {
    bodyAttrs: {
      class: 'antialiased stroke-2 hide-scroll'
    }
  }
})
export default class UserLayout extends mixins(AuthMixin, PortalMixin, ActiveUserMixin) {
  /**
   * Fetch the current active user
   *
   * @return {Promise<void>}
   */
  async fetch(): Promise<void> {
    this.fetchActiveUser()
  }

  /**
   * Reset scroll position on page navigation
   * @return {any}
   */
  @Watch('$route.path')
  resetScroll() {
    const divThatScrolls = this.$refs['scrolling-div'] as HTMLElement
    if (divThatScrolls) divThatScrolls.scrollTop = 0
  }
}
