import type { DirectiveOptions } from 'vue'
import type { DirectiveBinding } from 'vue/types/options'

// Consumed as `v-debounce:300="{func: updateDebounce, event: 'click'}"`
const debounceDirective: DirectiveOptions = {
  bind(el: HTMLElement, binding: DirectiveBinding) {
    // binding.value is optional on binding
    // This check will ensure type sanity on `fn` declaration
    if (!binding.value) return

    //? This is the function that will be called when the event is triggered
    const fn = binding.value.func as (value: unknown, event: Event) => void
    //? This is the event that will be listened to. Default value is `input` event. skipcq: JS-W1043
    const event = binding.value.event || 'input'

    // This will be used to reset timeout later
    // Always keep this outside of the `oninput` function scope
    let timeoutID: ReturnType<typeof setTimeout>

    const delay = binding.arg ? Number(binding.arg) : 400

    const delayFunc = (event: Event) => {
      clearTimeout(timeoutID)
      timeoutID = setTimeout(() => {
        const target = event.target as HTMLInputElement
        // Trigger the binding function
        fn(target.value, event)
      }, delay)
    }

    el.addEventListener(event, delayFunc)
  }
}

export default debounceDirective
