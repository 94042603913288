import Vue from 'vue'
import ini from 'highlight.js/lib/languages/ini'
import json from 'highlight.js/lib/languages/json'
import yaml from 'highlight.js/lib/languages/yaml'
import groovy from 'highlight.js/lib/languages/groovy'
import hljs from 'highlight.js/lib/core'

hljs.registerLanguage('toml', ini)
hljs.registerLanguage('json', json)
hljs.registerLanguage('yaml', yaml)
hljs.registerLanguage('groovy', groovy)

Vue.use(hljs.vuePlugin)
