import { render, staticRenderFns } from "./sidebar-only.vue?vue&type=template&id=d4abba68&"
import script from "./sidebar-only.vue?vue&type=script&lang=ts&"
export * from "./sidebar-only.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Sidebar: require('/app/components/Discover/Layout/Sidebar.vue').default,LoggedOutSidebar: require('/app/components/Layout/LoggedOutSidebar.vue').default,MobileNav: require('/app/components/Layout/MobileNav.vue').default,Palette: require('/app/components/ToitBar/Palette.vue').default})
