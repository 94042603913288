
import { Component, mixins, namespace, Watch } from 'nuxt-property-decorator'

import ContextMixin from '@/mixins/contextMixin'
import OwnerDetailMixin from '@/mixins/ownerDetailMixin'
import ActiveUserMixin from '~/mixins/activeUserMixin'
import ControlPanelBaseMixin from '~/mixins/control-panel/ControlPanelBaseMixin'
import PlanDetailMixin from '~/mixins/planDetailMixin'
import RepoListMixin from '~/mixins/repoListMixin'

import { AuthGetterTypes } from '~/store/account/auth'

// types
import { TeamPerms } from '~/types/permTypes'
import { TeamMemberRoleChoices } from '~/types/types'

import { isChristmasSeason } from '~/utils/easter'
import { containsElement } from '~/utils/ui'

const authStore = namespace('account/auth')
/**
 * Primary sidebar containing information and navigation for a user and the currently active owner.
 */
@Component({
  methods: {
    isChristmasSeason
  }
})
export default class Sidebar extends mixins(
  ActiveUserMixin,
  ContextMixin,
  OwnerDetailMixin,
  PlanDetailMixin,
  RepoListMixin,
  ControlPanelBaseMixin
) {
  @authStore.Getter(AuthGetterTypes.GET_LOGGED_IN)
  isLoggedIn: boolean

  public isCollapsed = false
  public collapsedSidebar = false
  public toggleCollapsed = false
  public isOpen = false
  public showAddRepoModal = false
  public largeScreenSize = 1024

  /**
   * Created hook for the component. Initializes sidebar state from values in cookies.
   *
   * @returns {void}
   */
  created(): void {
    this.isCollapsed = Boolean(this.$nuxt.$cookies.get('ui-state-sidebar-collapsed'))
    this.collapsedSidebar = Boolean(this.$nuxt.$cookies.get('ui-state-sidebar-collapsed'))
  }

  /**
   * Fetch hook for the sidebar.
   *
   * @returns {Promise<void>}
   */
  async fetch(): Promise<void> {
    await Promise.all([
      this.fetchContext(),
      this.fetchActiveUser(),
      ...(this.$config.onPrem ? [this.fetchIsViewerSuperadmin()] : [])
    ])
  }

  /**
   * Fetches usage parameters for the active owner.
   *
   * @returns {Promise<void>}
   */
  @Watch('$route.params.owner')
  async fetchMaxUsageInfo(): Promise<void> {
    const { owner: login, provider } = this.$route.params

    const params = { login, provider, refetch: true }
    await this.fetchMaxUsagePercentage(params)

    if (!this.$config.onPrem && process.client && this.isLoggedIn) {
      // Identify the user via RudderStack
      const { avatar, dateJoined: createdAt, email, firstName, id, lastName } = this.viewer

      if (id && email) {
        const userId = Buffer.from(id, 'base64').toString().toLowerCase().replace('user:', '')

        this.$sentry.setUser({
          email,
          id: userId
        })

        this.$rudder?.identify(userId, {
          avatar: avatar ?? '',
          createdAt,
          email,
          firstName,
          lastName
        })
      }

      // Identify the team via RudderStack
      const {
        avatar_url: team_avatar_url,
        id: groupId,
        subscribed_plan_info,
        team_name,
        type,
        vcs_provider_display
      } = this.activeDashboardContext

      if (type === 'team' && groupId && team_name) {
        const stringifiedGroupId = String(groupId)

        this.$rudder?.group(stringifiedGroupId, {
          groupType: 'organization',
          avatar: team_avatar_url,
          name: team_name,
          plan:
            typeof subscribed_plan_info === 'object'
              ? subscribed_plan_info.name
              : subscribed_plan_info,
          vcsProvider: vcs_provider_display
        })
      }
    }
  }

  /**
   * Fetches the count for activated repositories for the active owner.
   *
   * @returns {Promise<void>}
   */
  @Watch('activeOwner')
  async fetchRepoCount(refetch?: boolean): Promise<void> {
    const pageSize =
      // skipcq: JS-W1043
      (this.$localStore.get(
        `${this.activeProvider}-${this.activeOwner}-all-repos`,
        'currentPageSize'
      ) as number) || 10
    await this.fetchRepoList({
      provider: this.activeProvider,
      login: this.activeOwner,
      limit: pageSize,
      currentPageNumber: 1,
      query: '',
      refetch
    })
  }

  /**
   * Mounted hook for the component. Binds listeners for events and fetches repository count.
   *
   * @returns {void}
   */
  mounted(): void {
    const { owner: login, provider } = this.$route.params

    this.$root.$on('ui:show-sidebar-menu', () => {
      this.isCollapsed = false
      this.collapsedSidebar = false
      this.isOpen = true
    })

    this.$socket.$on('repo-onboarding-completed', () => {
      this.fetchRepoCount(true)
    })

    this.fetchRepoCount()
    this.fetchMaxUsagePercentage({ login, provider })
  }

  /**
   * Before destroy hook for the component. Unbinds listeners for events.
   *
   * @returns {void}
   */
  beforeDestroy(): void {
    this.$root.$off('ui:show-sidebar-menu')
    this.$socket.$off('repo-onboarding-completed')
  }

  get modalStyle(): string {
    if (this.collapsedSidebar) {
      return 'w-80 md:w-13'
    }
    if (this.isOpen) {
      return 'w-80 lg:left-0 left-0'
    }
    return 'w-80 lg:left-0 -left-full'
  }

  /**
   * Toggles sidebar between collapsed and uncollapsed state.
   *
   * @returns {void}
   */
  toggleSidebarCollapse(): void {
    const newVal = !this.isCollapsed

    if (newVal) {
      this.isCollapsed = !this.isCollapsed
      this.collapsedSidebar = !this.collapsedSidebar
    } else {
      this.collapsedSidebar = !this.collapsedSidebar
      setTimeout(() => {
        this.isCollapsed = !this.isCollapsed
      }, 120)
    }

    this.$nuxt.$cookies.set('ui-state-sidebar-collapsed', newVal)
  }

  /**
   * Closes the sidebar menu on mobile view.
   *
   * @param {Event} event
   * @returns {void}
   */
  closeMenu(event: Event): void {
    const target = event.target as HTMLElement
    const toggleButton = document.getElementById('mobile-menu-toggle')
    if (!toggleButton) {
      this.isOpen = false
    } else if (!containsElement(toggleButton, target) && target.id !== 'mobile-menu-toggle') {
      this.isOpen = false
    }
  }

  /**
   * Generates and returns routes for given repositories.
   *
   * @param {string} param - Parameter to generate route for.
   * @returns {string} Route for the given parameter.
   */
  public getRoute(params: string): string {
    return `/${this.provider}/${this.activeOwner}/${params}`
  }

  get provider(): string {
    return this.activeProvider
  }

  /**
   * Returns if the given route is active or not.
   *
   * @param {string} param - Route name to check for.
   * @returns {boolean} `true` if the route is active, otherwise returns `false`.
   */
  public isActive(params: string): boolean {
    return this.$route.name?.startsWith(params) ?? false
  }

  /**
   * Toggles between sidebar's `open` and `close` states.
   *
   * @returns {void}
   */
  toggleSidebar(): void {
    this.isOpen = !this.isOpen
  }

  get canActivateRepo(): boolean {
    const role = this.activeDashboardContext.role as TeamMemberRoleChoices
    return this.$gateKeeper.team(TeamPerms.ACTIVATE_ANALYSIS, role)
  }

  get currentYear() {
    /**
     * Return the current year.
     */
    return new Date().getFullYear()
  }

  get installationUrl(): string {
    if (this.viewer.connectedVcsProviders?.length && this.viewer.connectedVcsProviders.length > 1) {
      return '/installation/providers'
    }
    const provider = this.viewer.connectedVcsProviders?.[0]
    return provider
      ? this.installationUrls[this.$providerMetaMap[provider].value]
      : '/installation/providers'
  }

  /**
   * Disables scrolling for the main `<body>` element if sidebar is `open`, otherwise enables it.
   *
   * @returns {void}
   */
  @Watch('isOpen')
  disableScroll(newIsOpen: boolean): void {
    if (newIsOpen && process.client) {
      document.body.classList.add('no-scroll')
    } else if (process.client) {
      document.body.classList.remove('no-scroll')
    }
  }
}
