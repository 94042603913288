
import { Vue, Prop, Component } from 'nuxt-property-decorator'

import { getDefaultAvatar } from '~/utils/ui'

@Component({
  name: 'LogoutMenu',
  methods: { getDefaultAvatar }
})
export default class LogoutMenu extends Vue {
  @Prop({ required: true })
  email: string

  @Prop({ default: '' })
  avatar: string

  @Prop({ default: '' })
  fullName: string

  @Prop({ default: false })
  isLoading: boolean
}
