
import { Component, mixins, Prop } from 'nuxt-property-decorator'

import ActiveUserMixin from '~/mixins/activeUserMixin'
import AuthMixin from '~/mixins/authMixin'

import { getDefaultAvatar } from '~/utils/ui'
import { resolveNodes } from '~/utils/array'

import { superuserList } from '~/apollo/queries/enterprise/superuserList.gql'

import { EnterpriseUser } from '~/types/types'
import { GraphqlQueryResponse } from '~/types/apollo-graphql-types'
import { RequestAccessErrorVariant } from '~/types/errorTypes'

/**
 * Page for the request access error view.
 * Shows list of superusers/admins to contact to request access from.
 */
@Component({
  name: 'RequestAccess',
  methods: {
    getDefaultAvatar
  }
})
export default class RequestAccess extends mixins(ActiveUserMixin, AuthMixin) {
  @Prop({ default: '' })
  copytext: string

  @Prop({ default: 'request-access' })
  variant: RequestAccessErrorVariant

  superuserList: Array<EnterpriseUser> = []
  loading = false
  totalSuperuserCount = 0
  installationLogo = ''

  async fetch() {
    this.loading = true

    if (!this.viewer.id) {
      await this.fetchActiveUser({ refetch: true })
    }

    try {
      const response = (await this.$fetchGraphqlData(superuserList, {})) as GraphqlQueryResponse

      this.superuserList = resolveNodes(response.data.enterprise?.superusers)
      this.totalSuperuserCount = response.data.enterprise?.superusers?.totalCount ?? 0
      this.installationLogo = response.data.enterprise?.installation?.logo ?? ''
    } catch (e) {
      this.$logErrorAndToast(
        e as Error,
        'Unable to fetch details for the installation. Please contact support.'
      )
    } finally {
      this.loading = false
    }
  }

  get logoutMenuProps() {
    const { avatar, email, fullName } = this.viewer
    return {
      avatar,
      email,
      fullName
    }
  }

  get title() {
    return this.variant === 'insufficient-permissions'
      ? 'Insufficient Permissions'
      : 'Request access'
  }
}
