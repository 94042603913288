
import { Component, mixins } from 'nuxt-property-decorator'

import AuthMixin from '~/mixins/authMixin'
import ContextMixin from '~/mixins/contextMixin'
import PaletteMixin from '~/mixins/paletteMixin'
import PortalMixin from '~/mixins/portalMixin'
import { AppFeatures, TeamPerms } from '~/types/permTypes'
import { TeamMemberRoleChoices } from '~/types/types'

interface ITabsList {
  label: string
  icon: string
  link: string
  validator?: boolean
}

@Component({
  head: {
    bodyAttrs: {
      class: 'antialiased stroke-2 hide-scroll'
    }
  }
})
export default class DashboardLayout extends mixins(
  AuthMixin,
  ContextMixin,
  PortalMixin,
  PaletteMixin
) {
  get tabsList() {
    return [
      {
        label: 'Home',
        icon: 'home',
        link: this.$generateRoute([''])
      },
      {
        label: 'Repositories',
        icon: 'layers',
        link: this.getLink('repos')
      },
      {
        label: 'Reports',
        icon: 'pie-chart',
        link: this.getLink('reports'),
        validator: this.canViewReports
      },
      {
        label: 'Members',
        icon: 'users',
        link: this.getLink('members'),
        validator: this.showTeamMembers
      },
      {
        label: 'Settings',
        icon: 'wrench',
        link: this.getLink('settings'),
        validator: this.showTeamSettings
      }
    ] as ITabsList[]
  }

  /**
   * Fetch hook for the dashboard layout
   *
   * @returns {Promise<void>}
   */
  async fetch(): Promise<void> {
    await this.fetchActiveUser()
  }

  get allowedAutoOnboard(): boolean {
    if (!this.$gateKeeper.provider(AppFeatures.AUTO_ONBOARD, this.activeProvider)) {
      return false
    }

    if (this.activeDashboardContext && 'role' in this.activeDashboardContext) {
      const role = this.activeDashboardContext.role as TeamMemberRoleChoices
      return this.$gateKeeper.team(
        [TeamPerms.AUTO_ONBOARD_CRUD_FOR_TEMPLATE, TeamPerms.AUTO_ONBOARD_VIEW_TEMPLATE],
        role
      )
    }

    return false
  }

  get canViewReports(): boolean {
    return this.$gateKeeper.team(TeamPerms.VIEW_REPORTS, this.teamPerms.permission)
  }

  get showTeamMembers(): boolean {
    if (this.activeDashboardContext && 'type' in this.activeDashboardContext) {
      if (this.activeDashboardContext.type === 'user') {
        return false
      }

      const role = this.activeDashboardContext.role as TeamMemberRoleChoices
      return this.$gateKeeper.team(
        [TeamPerms.MANAGE_TEAM_MEMBERS, TeamPerms.VIEW_TEAM_MEMBERS],
        role
      )
    }
    return false
  }

  get showTeamSettings(): boolean {
    if (this.activeDashboardContext && 'type' in this.activeDashboardContext) {
      if (this.activeDashboardContext.type === 'user') {
        return false
      }
    }

    if (this.activeDashboardContext && 'role' in this.activeDashboardContext) {
      const role = this.activeDashboardContext.role as TeamMemberRoleChoices
      return (
        this.$gateKeeper.team(
          [
            TeamPerms.CHANGE_PLAN,
            TeamPerms.UPDATE_SEATS,
            TeamPerms.UPDATE_BILLING_DETAILS,
            TeamPerms.MANAGE_TEAM_MEMBERS,
            TeamPerms.VIEW_ACCESS_CONTROL_DASHBOARD,
            TeamPerms.DELETE_TEAM_ACCOUNT,
            TeamPerms.VIEW_TEAM_GENERAL_SETTINGS
          ],
          role
        ) || this.allowedAutoOnboard
      )
    }

    return false
  }

  /**
   * Generates and returns routes for the given path
   *
   * @param {string} [path='']
   * @returns {string}
   */
  getLink(path = ''): string {
    return this.$generateRoute([path], false, true)
  }

  /**
   * Method to compute the tab active status
   *
   * @param {ITabsList} tab
   * @returns {boolean}
   */
  getTabActiveStatus({ label, link }: ITabsList): boolean {
    if (label === 'Home') {
      return this.$route.name === 'provider-owner'
    }

    // Return the top level route path for `settings`
    return this.$route.path.startsWith(label === 'Settings' ? this.getLink('settings') : link)
  }
}
