import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=7288d842&"
import script from "./Sidebar.vue?vue&type=script&lang=ts&"
export * from "./Sidebar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZIcon: require('/app/components/zeal/ZIcon/ZIcon.vue').default,ZTag: require('/app/components/zeal/ZTag/ZTag.vue').default,SidebarItem: require('/app/components/Layout/Sidebar/SidebarItem.vue').default,AnalyzerLogo: require('/app/components/AnalyzerLogo.vue').default,ZButton: require('/app/components/zeal/ZButton/ZButton.vue').default,SupportMenu: require('/app/components/Layout/Sidebar/SupportMenu.vue').default,ExtrasMenu: require('/app/components/Layout/Sidebar/ExtrasMenu.vue').default,UserMenu: require('/app/components/Layout/Sidebar/UserMenu.vue').default,ZConfirm: require('/app/components/zeal/ZConfirm/ZConfirm.vue').default})
