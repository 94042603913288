var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"z-nav-item inline-flex items-end gap-2 px-1 pb-3 text-sm leading-none outline-none focus:outline-none",class:{
    'border-b-2': !_vm.removeIndicatorStyles,
    'text-vanilla-100': _vm.isActive && !_vm.disabled,
    [_vm.borderActive]: _vm.isActive && !_vm.disabled && !_vm.removeIndicatorStyles,
    'text-vanilla-400': !_vm.isActive && !_vm.disabled,
    'border-transparent hover:border-ink-100': !_vm.isActive && !_vm.disabled && !_vm.removeIndicatorStyles,
    'cursor-not-allowed border-transparent text-slate': _vm.disabled
  },attrs:{"role":"tab","aria-selected":_vm.selected,"disabled":_vm.disabled},on:{"click":function($event){_vm.action && _vm.action()}}},[(_vm.icon)?_c('z-icon',{attrs:{"icon":_vm.icon,"size":"small","color":_vm.isActive && !_vm.disabled ? 'vanilla-100' : 'vanilla-400'}}):_vm._e(),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }