import { render, staticRenderFns } from "./RequestAccess.vue?vue&type=template&id=7baf0ab4&"
import script from "./RequestAccess.vue?vue&type=script&lang=ts&"
export * from "./RequestAccess.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZIcon: require('/app/components/zeal/ZIcon/ZIcon.vue').default,ZDivider: require('/app/components/zeal/ZDivider/ZDivider.vue').default,LogoutMenu: require('/app/components/Common/LogoutMenu.vue').default,EnterpriseInstallationLogo: require('/app/components/Common/EnterpriseInstallationLogo.vue').default,ZAvatar: require('/app/components/zeal/ZAvatar/ZAvatar.vue').default,HeroLayout: require('/app/components/Layout/HeroLayout.vue').default})
