var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"overflow-x-auto border-b border-slate-400 bg-ink-300"},[_c('div',{staticClass:"flex items-center gap-x-3 p-3",attrs:{"id":"header"}},[_c('h2',{staticClass:"inline-flex items-center gap-x-3 font-medium text-vanilla-100"},[_c('nuxt-link',{staticClass:"inline-flex flex-shrink-0 items-center gap-x-2",attrs:{"to":_vm.$generateRoute()}},[_c('client-only',{scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"h-7 w-7 animate-pulse rounded-full bg-ink-200"})]},proxy:true}])},[(_vm.activeDashboardContext.avatar_url)?_c('z-avatar',{staticClass:"flex-shrink-0",attrs:{"image":_vm.activeDashboardContext.avatar_url,"user-name":_vm.activeDashboardContext.login,"fallback-image":_vm.getDefaultAvatar(
                _vm.activeDashboardContext.login,
                _vm.activeDashboardContext.type === 'user'
              ),"size":"sm","stroke":"bg-ink-100 p-0.5"}}):_vm._e()],1),_vm._v(" "),_c('span',{staticClass:"cursor-pointer text-base font-medium"},[_vm._v(_vm._s(_vm.activeDashboardContext.team_name || _vm.activeDashboardContext.login))])],1)],1),_vm._v(" "),_c('span',{staticClass:"inline-flex items-center space-x-3"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          `Open ${_vm.activeDashboardContext.login} on ${_vm.activeDashboardContext.vcs_provider_display}`
        ),expression:"\n          `Open ${activeDashboardContext.login} on ${activeDashboardContext.vcs_provider_display}`\n        "}],staticClass:"flex h-6 items-center",attrs:{"href":_vm.activeDashboardContext.vcs_url,"target":"_blank","rel":"noopener noreferrer"}},[(_vm.activeDashboardContext.vcs_provider)?_c('z-tag',{staticClass:"border border-slate-400",attrs:{"icon-left":_vm.repoVCSIcon,"spacing":"p-0.5","bg-color":"ink-200"}}):_vm._e()],1),_vm._v(" "),(
          !_vm.$config.onPrem &&
          _vm.activeDashboardContext.type === 'team' &&
          (_vm.hasPaidPlan || _vm.canVisitBillingPage)
        )?_c(_vm.canVisitBillingPage ? 'nuxt-link' : 'span',{tag:"component",attrs:{"to":_vm.canVisitBillingPage ? _vm.$generateRoute(['settings', 'billing'], false, true) : false}},[(_vm.hasPaidPlan)?_c('z-tag',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(`This account is on the ${_vm.planName} plan`),expression:"`This account is on the ${planName} plan`"}],staticClass:"gap-x-1 border border-slate-400 py-1 font-medium leading-none",attrs:{"icon-left":"zap","size":"x-small","bg-color":"ink-200","spacing":"px-2.5","text-size":"xs"}},[_vm._v("\n          "+_vm._s(_vm.planName)+"\n        ")]):(_vm.canVisitBillingPage)?_c('z-tag',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('See upgrade options'),expression:"'See upgrade options'"}],staticClass:"h-6 border border-slate-400 text-center font-semibold uppercase leading-none tracking-wider text-vanilla-300 hover:bg-ink-100 hover:text-vanilla-100",attrs:{"icon-left":"star","bg-color":"ink-200","spacing":"px-2.5","text-size":"xs"}},[_c('span',[_vm._v("Upgrade")])]):_vm._e()],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }