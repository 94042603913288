
import Vue from 'vue'
import ZButton from '../ZButton/ZButton.vue'
import ZDialogGeneric from '../ZDialogGeneric/ZDialogGeneric.vue'

export default Vue.extend({
  name: 'ZModal',
  props: {
    title: {
      type: String,
      default: undefined
    },
    subtitle: {
      type: String,
      default: undefined
    },
    closeAfterPrimaryAction: {
      type: Boolean,
      default: true
    },
    primaryActionLabel: {
      type: String,
      default: 'Confirm'
    },
    secondaryActionLabel: {
      type: String,
      default: 'Cancel'
    },
    primaryActionIcon: {
      type: String,
      default: 'check'
    },
    primaryActionType: {
      type: String,
      default: 'primary'
    },
    showSecondaryAction: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ZButton,
    ZDialogGeneric
  },
  methods: {
    closeDialog(): void {
      this.$emit('onClose')
    },
    primaryAction(close: () => void): void {
      this.$emit('primaryAction')
      if (this.closeAfterPrimaryAction) {
        close()
      }
    }
  }
})
