export default class WebSocketManager {
  constructor(url, emitter, reconnectInterval) {
    this.url = url;
    this.emitter = emitter;
    this.reconnectInterval = reconnectInterval;
    this.ws = new WebSocket(this.url);
    this.connect();
  }
  connect() {
    this.reconnectInterval = this.reconnectInterval || 1e3;
    this.ws = new WebSocket(this.url);
    this.ws.onmessage = (message) => {
      try {
        const {event, data} = JSON.parse(message.data);
        this.emitter.$emit(event, data);
      } catch (err) {
        this.emitter.$emit("message", message);
      }
    };
    this.ws.onclose = (event) => {
      if (event) {
        if (event.code !== 1e3) {
          const maxReconnectInterval = 3e3;
          setTimeout(() => {
            if (this.reconnectInterval < maxReconnectInterval) {
              this.reconnectInterval += 1e3;
            }
            this.connect();
          }, this.reconnectInterval);
        }
      }
    };
    this.ws.onerror = (error) => {
      console.error(error);
      this.ws.close();
    };
  }
  ready() {
    return new Promise((resolve) => {
      if (this.ws.readyState !== this.ws.OPEN) {
        this.ws.onopen = () => {
          this.reconnectInterval = 1e3;
          resolve();
        };
      } else {
        resolve();
      }
    });
  }
  async send(message) {
    await this.ready();
    const parsedMessage = typeof message === "string" ? message : JSON.stringify(message);
    return this.ws.send(parsedMessage);
  }
  close(code, reason) {
    this.ws.close(code, reason);
  }
}
