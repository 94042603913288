
import { Component, mixins, namespace, Watch } from 'nuxt-property-decorator'

import ActiveUserMixin from '~/mixins/activeUserMixin'
import AuthMixin from '~/mixins/authMixin'
import { isChristmasSeason } from '~/utils/easter'

import { DirectoryActions, DirectoryGetters } from '~/store/directory/directory'
import { DiscoverUserActions } from '~/store/discover/user'
import { Analyzer } from '~/types/types'
import { containsElement } from '~/utils/ui'

const directoryStore = namespace('directory/directory')
const discoverUserStore = namespace('discover/user')

@Component({
  methods: {
    isChristmasSeason
  }
})
export default class Sidebar extends mixins(ActiveUserMixin, AuthMixin) {
  @directoryStore.Getter(DirectoryGetters.DIRECTORY_ANALYZERS)
  analyzerList: Analyzer[]

  @directoryStore.Action(DirectoryActions.FETCH_ANALYZER_DIR_LIST)
  fetchAnalyzers: () => Promise<void>

  @discoverUserStore.State
  watchedRepositoriesCount: number

  @discoverUserStore.Action(DiscoverUserActions.FETCH_WATCHED_REPOSITORIES_COUNT)
  fetchWatchedRepositoriesCount: () => Promise<void>

  public isCollapsed = false
  public collapsedSidebar = false
  public toggleCollapsed = false
  public isOpen = false
  public showInDiscoverInfoDialog = false
  public showUpdateTechnologiesModal = false
  public debounceTimer: ReturnType<typeof setTimeout>

  async fetch(): Promise<void> {
    if (this.loggedIn) {
      await this.fetchActiveUser()
    }
    await this.fetchAnalyzers()
  }

  openSidebar() {
    this.isCollapsed = false
    this.collapsedSidebar = false
    this.isOpen = true
  }

  closeSidebar() {
    this.isCollapsed = true
    this.collapsedSidebar = true
    this.isOpen = false
  }

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed
    this.collapsedSidebar = !this.collapsedSidebar
    this.isOpen = !this.isOpen
  }

  mounted() {
    if (this.loggedIn) {
      this.fetchWatchedRepositoriesCount()
    }
    this.$root.$on('ui:show-sidebar-menu', this.openSidebar)
    this.$root.$on('ui:hide-sidebar-menu', this.closeSidebar)
    this.$root.$on('ui:toggle-sidebar-menu', this.toggleSidebar)
  }

  beforeDestroy() {
    this.$root.$off('ui:show-sidebar-menu', this.openSidebar)
    this.$root.$off('ui:hide-sidebar-menu', this.closeSidebar)
    this.$root.$off('ui:toggle-sidebar-menu', this.toggleSidebar)
  }

  closeMenu(event: Event): void {
    const target = event.target as HTMLElement
    const toggleButton = document.getElementById('mobile-menu-toggle')
    if (!toggleButton) {
      this.isOpen = false
    } else if (!containsElement(toggleButton, target) && target.id !== 'mobile-menu-toggle') {
      this.isOpen = false
    }
  }

  get currentYear() {
    /**
     * Return the current year.
     */
    return new Date().getFullYear()
  }

  get activeDashboardHome(): string {
    return this.activeDashboardContext.type === 'team' ? 'Team home' : 'Home'
  }

  @Watch('isOpen')
  disableScroll(newIsOpen: boolean) {
    if (newIsOpen && process.client) {
      document.body.classList.add('no-scroll')
    } else if (process.client) {
      document.body.classList.remove('no-scroll')
    }
  }
}
