
import { Component, Prop, mixins } from 'nuxt-property-decorator'

import { RepoPerms, TeamPerms } from '~/types/permTypes'
import { Repository, TeamMemberRoleChoices } from '~/types/types'

import RepoDetailMixin from '~/mixins/repoDetailMixin'
import RoleAccessMixin from '~/mixins/roleAccessMixin'

@Component({
  name: 'RepoInactive'
})
export default class RepoInactive extends mixins(RepoDetailMixin, RoleAccessMixin) {
  @Prop()
  defaultBranchName: Repository['defaultBranchName']

  @Prop()
  id: Repository['id']

  @Prop({ default: false })
  activateAnalysisLoading: boolean

  get canViewerUpgrade(): boolean {
    return this.$gateKeeper.team(TeamPerms.CHANGE_PLAN, this.teamPerms.permission)
  }

  get configNotAdded(): boolean {
    return this.repository.errorCode === 3001
  }

  get noticeTitle(): string {
    if (!this.canActivateRepo) {
      return 'This repository is not activated'
    }

    if (this.repository.canBeActivated || this.repository.isActivated) {
      return 'Activate analysis on this repository'
    }

    if (this.canViewerUpgrade) {
      return 'Upgrade to activate this repository'
    }

    return 'Repository cannot be activated'
  }

  get canActivateRepo(): boolean {
    const role = this.activeDashboardContext.role as TeamMemberRoleChoices
    return (
      this.$gateKeeper.team(TeamPerms.ACTIVATE_ANALYSIS, role) ||
      this.$gateKeeper.repo(RepoPerms.ACTIVATE_REPOSITORY, this.repoPerms.permission)
    )
  }

  get canRequestRepoActivation(): boolean {
    return this.$gateKeeper.repo(RepoPerms.READ_REPO, this.repoPerms.permission)
  }
}
