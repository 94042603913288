import { render, staticRenderFns } from "./RecentlyActiveRepo.vue?vue&type=template&id=0cf8ab98&"
import script from "./RecentlyActiveRepo.vue?vue&type=script&lang=ts&"
export * from "./RecentlyActiveRepo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZIcon: require('/app/components/zeal/ZIcon/ZIcon.vue').default,ZMenuItem: require('/app/components/zeal/ZMenu/ZMenuItem/ZMenuItem.vue').default,ZMenuSection: require('/app/components/zeal/ZMenu/ZMenuSection/ZMenuSection.vue').default,ZMenu: require('/app/components/zeal/ZMenu/ZMenu.vue').default,ZTag: require('/app/components/zeal/ZTag/ZTag.vue').default,SidebarItem: require('/app/components/Layout/Sidebar/SidebarItem.vue').default})
