import { render, staticRenderFns } from "./ContextSwitcher.vue?vue&type=template&id=bcff58f8&"
import script from "./ContextSwitcher.vue?vue&type=script&lang=ts&"
export * from "./ContextSwitcher.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZAvatar: require('/app/components/zeal/ZAvatar/ZAvatar.vue').default,ZIcon: require('/app/components/zeal/ZIcon/ZIcon.vue').default,ZInput: require('/app/components/zeal/ZInput/ZInput.vue').default,ZMenuSection: require('/app/components/zeal/ZMenu/ZMenuSection/ZMenuSection.vue').default,ZMenuItem: require('/app/components/zeal/ZMenu/ZMenuItem/ZMenuItem.vue').default,ZDivider: require('/app/components/zeal/ZDivider/ZDivider.vue').default,ZMenu: require('/app/components/zeal/ZMenu/ZMenu.vue').default})
