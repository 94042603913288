// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/errors/500/stars.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/errors/500/500-fg.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".five-hundred[data-v-945f4052]{background:#120000;background-size:contain;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.text-container[data-v-945f4052]{background:rgba(92,114,207,.2);border-radius:8px}.foreground[data-v-945f4052]{width:1538px;height:196px;background-position:50%;background-repeat:no-repeat;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.smoke[data-v-945f4052]{margin-right:-35rem;bottom:5.5rem;height:85px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
