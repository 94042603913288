import { render, staticRenderFns } from "./control-panel.vue?vue&type=template&id=f01eba96&scoped=true&"
import script from "./control-panel.vue?vue&type=script&lang=ts&"
export * from "./control-panel.vue?vue&type=script&lang=ts&"
import style0 from "./control-panel.vue?vue&type=style&index=0&id=f01eba96&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f01eba96",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControlPanelSidebar: require('/app/components/ControlPanel/Layout/ControlPanelSidebar.vue').default,MobileNav: require('/app/components/Layout/MobileNav.vue').default,ZBreadcrumbItem: require('/app/components/zeal/ZBreadcrumb/ZBreadcrumbItem/ZBreadcrumbItem.vue').default,ZBreadcrumb: require('/app/components/zeal/ZBreadcrumb/ZBreadcrumb.vue').default,ZButton: require('/app/components/zeal/ZButton/ZButton.vue').default,ZTab: require('/app/components/zeal/ZTab/ZTab.vue').default,SidebarItem: require('/app/components/Layout/Sidebar/SidebarItem.vue').default})
