
import Vue from 'vue'

export default Vue.extend({
  name: 'ZDialogGeneric',
  data() {
    return {
      showInternal: false
    }
  },
  mounted() {
    this.showInternal = true
    document.addEventListener('keyup', this.handleKeyup)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyup)
  },
  methods: {
    close(): void {
      this.showInternal = false
      setTimeout(() => {
        this.$emit('onClose')
      }, 200)
    },
    handleKeyup(e: KeyboardEvent): void {
      if (e.key === 'Escape') {
        this.close()
      }
    }
  },
  props: {
    transparent: {
      type: Boolean,
      default: false
    }
  }
})
