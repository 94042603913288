
import { Component, Prop, Vue } from 'nuxt-property-decorator'

import { variants } from '~/types/zealTypes'

@Component({ name: 'ZTagV2' })
export default class ZTagV2 extends Vue {
  @Prop({ type: String, required: true, validator: (value: string) => variants.includes(value) })
  type: string

  @Prop({ type: Boolean, default: false })
  hideIcon: boolean
}
