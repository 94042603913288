import { Middleware } from '@nuxt/types'

import { ActiveUserActions, ActiveUserGetterTypes } from '~/store/user/active'

const restrictControlPanelAccess: Middleware = async ({ store, error, $config }) => {
  if ($config.onPrem)
    try {
      await store.dispatch(`user/active/${ActiveUserActions.FETCH_VIEWER_SUPERADMIN}`)

      const isViewerSuperadmin =
        store.getters[`user/active/${ActiveUserGetterTypes.IS_VIEWER_SUPERADMIN}`]

      // Prevent user from opening control panel
      if (!isViewerSuperadmin) {
        error({ statusCode: 404, message: 'This page is not real' })
      }
    } catch (e) {}
  else error({ statusCode: 404, message: 'This page is not real' })
}

export default restrictControlPanelAccess
