
import { Component, Prop, Vue } from 'nuxt-property-decorator'

/**
 * Support Menu, for the sidebar
 */
@Component({})
export default class SupportMenu extends Vue {
  @Prop()
  isCollapsed: boolean
}
