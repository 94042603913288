var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"w-full",class:[
    {
      'rounded-md border border-dashed border-slate-400': _vm.showBorder,
      'flex flex-wrap items-start justify-center gap-x-7 gap-y-8 md:flex-nowrap': _vm.useV2
    },
    _vm.padding
  ]},[_c('empty-state-picture',{class:{ 'w-full md:w-auto': _vm.useV2 },attrs:{"webp-image-path":_vm.webpImagePath,"png-image-path":_vm.pngImagePath,"svg-image-path":_vm.svgImagePath,"width":_vm.imageWidth ? _vm.imageWidth : 'w-auto',"alt-text":_vm.altText,"height":_vm.imageWidth ? undefined : 'h-17 md:h-20'}}),_vm._v(" "),_c('div',{class:[{ 'text-center md:text-left': _vm.useV2 }, { [_vm.contentWidth]: _vm.useV2 }]},[_c('h3',{staticClass:"text-vanilla-100",class:_vm.useV2
          ? 'mt-7 text-base font-medium md:mt-0'
          : 'mt-7 text-center text-lg font-semibold md:mt-5'},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.title))]})],2),_vm._v(" "),_c('p',{staticClass:"text-sm text-vanilla-400",class:_vm.useV2 ? 'mt-2 leading-6' : 'mx-auto mt-2 max-w-md text-center sm:mt-1'},[_vm._t("subtitle",function(){return [_vm._v(_vm._s(_vm.subtitle)+" ")]})],2),_vm._v(" "),(_vm.$slots.action)?_c('div',{class:_vm.useV2 ? 'mt-5' : 'mt-5 text-center'},[_vm._t("action")],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }