import { Inject, Context } from '@nuxt/types/app'
import { stripTrailingSlash } from '~/utils/string'

// import { Plugin } from '@nuxt/types'
type GenerateRouteFunc = (
  path: Array<string>,
  includeRepoInPath?: boolean,
  teamPage?: boolean
) => string

declare module 'vue/types/vue' {
  interface Vue {
    $generateRoute: GenerateRouteFunc
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $generateRoute: GenerateRouteFunc
  }
  interface Context {
    $generateRoute: GenerateRouteFunc
  }
}

declare module 'vuex/types/index' {
  // skipcq: JS-0387
  interface Store<S> {
    $generateRoute: GenerateRouteFunc
  }
}

export default (context: Context, inject: Inject): void => {
  const generateRoute: GenerateRouteFunc = (
    path?: Array<string> | string,
    includeRepoInPath = true,
    teamPage = false
  ) => {
    const baseRoute = ['']

    const { params } = context.route

    // Only include 'orgs' in route for team level pages
    // In case someone passes true for teamPage, we do an additional check whether repo exists in route params
    if (teamPage && !params.repo) {
      baseRoute.push('orgs')
    }

    baseRoute.push(params.provider, params.owner)

    if (params.repo && includeRepoInPath) {
      baseRoute.push(params.repo)
    }

    if (path && Array.isArray(path)) {
      return stripTrailingSlash(baseRoute.concat(...path).join('/'))
    } else if (path) {
      path = stripTrailingSlash(path)
      return baseRoute.concat(path).join('/')
    }

    return baseRoute.join('/')
  }
  inject('generateRoute', generateRoute)
}
