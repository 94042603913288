const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['betaOnly'] = require('../middleware/betaOnly.ts')
middleware['betaOnly'] = middleware['betaOnly'].default || middleware['betaOnly']

middleware['featureGate'] = require('../middleware/featureGate.ts')
middleware['featureGate'] = middleware['featureGate'].default || middleware['featureGate']

middleware['hidePrivateRepo'] = require('../middleware/hidePrivateRepo.ts')
middleware['hidePrivateRepo'] = middleware['hidePrivateRepo'].default || middleware['hidePrivateRepo']

middleware['licenseValidation'] = require('../middleware/licenseValidation.ts')
middleware['licenseValidation'] = middleware['licenseValidation'].default || middleware['licenseValidation']

middleware['perm'] = require('../middleware/perm.ts')
middleware['perm'] = middleware['perm'].default || middleware['perm']

middleware['protectMonorepoRoutes'] = require('../middleware/protectMonorepoRoutes.ts')
middleware['protectMonorepoRoutes'] = middleware['protectMonorepoRoutes'].default || middleware['protectMonorepoRoutes']

middleware['redirectToHome'] = require('../middleware/redirectToHome.ts')
middleware['redirectToHome'] = middleware['redirectToHome'].default || middleware['redirectToHome']

middleware['restrictControlPanelAccess'] = require('../middleware/restrictControlPanelAccess.ts')
middleware['restrictControlPanelAccess'] = middleware['restrictControlPanelAccess'].default || middleware['restrictControlPanelAccess']

middleware['restrictOnboarding'] = require('../middleware/restrictOnboarding.ts')
middleware['restrictOnboarding'] = middleware['restrictOnboarding'].default || middleware['restrictOnboarding']

middleware['teamOnly'] = require('../middleware/teamOnly.ts')
middleware['teamOnly'] = middleware['teamOnly'].default || middleware['teamOnly']

middleware['validateProvider'] = require('../middleware/validateProvider.ts')
middleware['validateProvider'] = middleware['validateProvider'].default || middleware['validateProvider']

export default middleware
