
import { Component, mixins } from 'nuxt-property-decorator'

import ActiveUserMixin from '~/mixins/activeUserMixin'
import RepoDetailMixin from '~/mixins/repoDetailMixin'
import RoleAccessMixin from '~/mixins/roleAccessMixin'
import RouteParamsMixin from '~/mixins/routeParamsMixin'

import { AppFeatures, RepoPerms, TeamPerms } from '~/types/permTypes'
import { RepositoryKindChoices } from '~/types/types'

interface TabLink {
  icon: string
  label: string
  link?: string
  loginRequired?: boolean
  matchName?: string[]
  perms?: RepoPerms[]
  pattern?: RegExp
  gateFeature?: AppFeatures[]
  forBeta?: boolean
  repoKindChoices: RepositoryKindChoices[]
}

@Component({})
export default class RepoHeader extends mixins(
  RouteParamsMixin,
  RepoDetailMixin,
  RoleAccessMixin,
  ActiveUserMixin
) {
  async fetch(): Promise<void> {
    await this.fetchRepoPerms(this.baseRouteParams)

    if (this.isMonorepo) {
      return
    }

    await this.fetchMetrics({ ...this.baseRouteParams, fetchPerms: true })

    //? Pre-load first link of metric
    const firstMetric = this.repository.metricsCaptured?.[0]
    if (firstMetric) {
      const metricRecord = this.navItems.findIndex((navItem) => navItem.label === 'Metrics')
      this.navItems[metricRecord].link = `metrics/${firstMetric.shortcode}`
    }
  }

  get canReadTeamPage(): boolean {
    if (this.teamPerms.permission && this.activeOwner === this.owner) {
      return this.$gateKeeper.team(TeamPerms.VIEW_TEAM_HOME, this.teamPerms.permission)
    }
    return false
  }

  get icon() {
    const { isPrivate } = this.repository

    if (this.isSubRepository) {
      return isPrivate ? 'folder-locked' : 'folder-globe'
    }

    if (this.repoKind === RepositoryKindChoices.Repo) {
      return isPrivate ? 'z-lock' : 'globe'
    }

    return 'monorepo'
  }

  get isMonorepo(): boolean {
    return this.repoKind === RepositoryKindChoices.Monorepo
  }

  get isSubRepository(): boolean {
    return this.repoKind === RepositoryKindChoices.Subrepo
  }

  get monorepoName(): string {
    return this.repository.parentMonorepo?.name ?? ''
  }

  get monorepoDisplayName(): string {
    return this.repository.parentMonorepo?.displayName ?? ''
  }

  get navItems(): TabLink[] {
    return [
      {
        icon: this.isMonorepo ? 'folder-tree' : 'gauge',
        label: this.isMonorepo ? 'Sub-repositories' : 'Overview',
        pattern: new RegExp(/^provider-owner-repo$/),
        repoKindChoices: [
          RepositoryKindChoices.Repo,
          RepositoryKindChoices.Monorepo,
          RepositoryKindChoices.Subrepo
        ]
      },
      {
        icon: 'flag',
        label: 'Issues',
        link: 'issues',
        pattern: new RegExp(/^provider-owner-repo-issue*/),
        repoKindChoices: [RepositoryKindChoices.Repo, RepositoryKindChoices.Subrepo]
      },
      {
        icon: 'bar-chart',
        label: 'Metrics',
        link: 'metrics',
        pattern: new RegExp(/^provider-owner-repo-metrics-.*$/),
        repoKindChoices: [RepositoryKindChoices.Repo, RepositoryKindChoices.Subrepo]
      },
      {
        icon: 'pie-chart',
        label: 'Reports',
        link: 'reports/owasp-top-10',
        loginRequired: true,
        perms: [RepoPerms.VIEW_REPORTS],
        pattern: new RegExp(/^provider-owner-repo-reports-*/),
        repoKindChoices: [RepositoryKindChoices.Repo, RepositoryKindChoices.Subrepo]
      },
      {
        icon: 'history',
        label: 'History',
        link: 'history/runs',
        pattern: new RegExp(/^provider-owner-repo-(history|run|runs|transforms|autofix)/),
        repoKindChoices: [RepositoryKindChoices.Repo, RepositoryKindChoices.Subrepo]
      },
      {
        icon: 'wrench',
        label: 'Settings',
        link: 'settings/general',
        loginRequired: true,
        perms: [
          RepoPerms.GENERATE_SSH_KEY_PAIR,
          RepoPerms.CHANGE_DEFAULT_ANALYSIS_BRANCH,
          RepoPerms.CHANGE_ISSUE_TYPES_TO_REPORT,
          RepoPerms.CHANGE_ISSUES_TO_TYPE_TO_BLOCK_PRS_ON,
          RepoPerms.DEACTIVATE_ANALYSIS_ON_REPOSITORY,
          RepoPerms.ADD_REMOVE_MEMBERS,
          RepoPerms.UPDATE_ROLE_OF_EXISTING_MEMBERS,
          RepoPerms.VIEW_AUDIT_LOG
        ],
        pattern: new RegExp(/^provider-owner-repo-settings-*/),
        repoKindChoices: [
          RepositoryKindChoices.Repo,
          RepositoryKindChoices.Monorepo,
          RepositoryKindChoices.Subrepo
        ]
      }
    ]
  }

  get repoKind(): RepositoryKindChoices {
    return this.repository.kind
  }

  get repoVCSIcon(): string {
    const provider = this.repository.vcsProvider
    return this.$providerMetaMap[provider].icon ?? ''
  }

  get showMetadataViewToggleButton(): boolean {
    return (
      (this.repository.isActivated || this.repository.errorCode === 3003) &&
      this.$route.name === 'provider-owner-repo'
    )
  }

  get subRepositoryPath(): string {
    // Check if monorepoDisplayName is defined and not empty.
    if (this.monorepoDisplayName && this.repository?.displayName) {
      const parts = this.repository.displayName.split(this.monorepoDisplayName)
      return parts.length > 1 ? parts[1] : ''
    }
    return ''
  }

  get teamPageUrl(): string {
    const { provider, owner } = this.$route.params
    return ['', provider, owner].join('/')
  }

  async toggleStar(isStarred: boolean) {
    this.updateRepositoryInStore({ ...this.repository })

    const response = await this.updateStarredRepo({
      action: isStarred ? 'ADD' : 'REMOVE',
      repoId: this.repository.id
    })

    if (!response.ok) {
      this.updateRepositoryInStore({ ...this.repository, isStarred: !isStarred })
      this.$toast.danger(
        "Couldn't star this repository, if the issue persists please contact support"
      )
    }

    this.fetchBasicRepoDetails({
      ...this.baseRouteParams,
      refetch: true
    })
  }

  public activeLink(item: TabLink): boolean {
    if (item.pattern && this.$route.name) {
      return item.pattern.test(this.$route.name)
    }
    return false
  }

  get allowStar(): boolean {
    if (this.loggedIn) {
      return this.$gateKeeper.repo(RepoPerms.ALLOW_STAR, this.repoPerms.permission)
    }
    return false
  }

  get tagState(): string {
    if (this.isMonorepo) {
      return 'aqua'
    }

    const { errorCode, isActivated } = this.repository

    if (errorCode) {
      return errorCode === 3003 ? 'aqua' : 'cherry'
    }

    return isActivated ? 'juniper' : 'honey'
  }

  get tagText() {
    if (this.isMonorepo) {
      return 'MONOREPO'
    }

    const { errorCode, isActivated } = this.repository

    if (errorCode) {
      return errorCode === 3003 ? 'Pending commit' : 'Error'
    }

    return isActivated ? 'Active' : 'Inactive'
  }

  isNavLinkVisible(item: TabLink): boolean {
    const isItemValidForRepoKind = item.repoKindChoices.includes(this.repoKind)

    if (!isItemValidForRepoKind) {
      return false
    }

    if (item.loginRequired && !this.loggedIn) {
      return false
    }

    const allowedForProvider = item.gateFeature
      ? this.$gateKeeper.provider(item.gateFeature, this.provider)
      : true

    const allowedForRepo = item.perms
      ? this.$gateKeeper.repo(item.perms, this.repoPerms.permission)
      : true

    const allowForbeta =
      this.$config.onPrem || (item.forBeta ? Boolean(this.viewer.isBetaTester) : true)

    return allowedForRepo && allowedForProvider && allowForbeta
  }

  triggerExpand() {
    this.$root.$emit('ui:show-sidebar-menu')
  }
}
