import { Component, Vue } from 'nuxt-property-decorator'

import { RepoConfigInterface } from '~/store/repository/detail'
import { TransformerInterface } from '~/store/analyzer/list'
import { AnalyzerAnalyzerType } from '~/types/types'

const TAB = '  '
const NEWLINE = '\n'
const INDENT = `${NEWLINE}${TAB}`

export const CONFIG_MAP_ANALYZER_TYPE = {
  [AnalyzerAnalyzerType.Core]: AnalyzerAnalyzerType.Core,
  [AnalyzerAnalyzerType.Community]: AnalyzerAnalyzerType.Community,
  [AnalyzerAnalyzerType.Custom]: AnalyzerAnalyzerType.Custom,
  core: AnalyzerAnalyzerType.Core,
  community: AnalyzerAnalyzerType.Community,
  custom: AnalyzerAnalyzerType.Custom
}

/**
 * Parses an array of strings and returns a formatted string representation.
 *
 * @param arr - The array of strings to parse.
 * @param indent - The number of indentation levels for the formatted string. Default is 1.
 * @returns A formatted string representation of the parsed array.
 */
function parseArray(arr: string[], indent = 1) {
  if (arr.length === 0) {
    return ''
  }
  if (arr.length === 1) {
    return JSON.stringify(arr)
  }

  const candidates = arr
    .filter(Boolean)
    .map((val: string): string => {
      return JSON.stringify(val)
    })
    .join(`,${NEWLINE}${TAB.repeat(indent)}`)
  return `[${NEWLINE}${TAB.repeat(indent)}${candidates}${NEWLINE}${TAB.repeat(indent - 1)}]`
}

@Component
export default class TomlGeneratorMixin extends Vue {
  /**
   * Asgard sends 'community' as analyzer type when accessing `repository.config` but `AnalyzerAnalyzerType.Community` when accessing `analyzer.analyzerType`.
   * This map standardizes the analyzer type to be used when verifying the analyzer type.
   */
  readonly CONFIG_MAP_ANALYZER_TYPE = CONFIG_MAP_ANALYZER_TYPE

  /**
   * Generates a TOML template based on the provided configuration, test patterns, and exclude patterns.
   * @param config - The repository configuration.
   * @param testPatterns - The test patterns to include in the TOML template.
   * @param excludePatterns - The exclude patterns to include in the TOML template.
   * @returns The generated TOML template as a string.
   */
  tomlTemplate(
    config: RepoConfigInterface,
    testPatterns: RepoConfigInterface['test_patterns'],
    excludePatterns: RepoConfigInterface['exclude_patterns']
  ): string {
    const toml = [`version = ${JSON.stringify(config.version)}`]

    if (testPatterns?.length) {
      if (testPatterns.filter(Boolean).length) {
        toml.push(this.testPatternsTemplate(testPatterns))
      }
    }

    if (excludePatterns?.length) {
      if (excludePatterns.filter(Boolean).length) {
        toml.push(this.excludePatternsTemplate(excludePatterns))
      }
    }

    if (config?.analyzers?.length) {
      toml.push(this.analyzersTemplate(config.analyzers))
    }

    if (config?.transformers?.length) {
      toml.push(this.transformersTemplate(config.transformers))
    }

    return toml.join(NEWLINE.repeat(2))
  }

  /**
   * Generates a toml template string for the test patterns section.
   * @param testPatterns - An array of test patterns.
   * @returns The toml template string of the test patterns section.
   */
  testPatternsTemplate(testPatterns: string[]): string {
    // Returns a toml template string of test patterns section
    return testPatterns.length ? `test_patterns = ${parseArray(testPatterns)}` : ''
  }

  /**
   * Returns a toml template string of exclude patterns section.
   * @param excludePatterns - An array of exclude patterns.
   * @returns The toml template string of exclude patterns section.
   */
  excludePatternsTemplate(excludePatterns: string[]): string {
    // Returns a toml template string of exclude patterns section
    return excludePatterns.length ? `exclude_patterns = ${parseArray(excludePatterns)}` : ''
  }

  /**
   * Generates a toml template string for the analyzers section, including its meta.
   *
   * @param allAnalyzers - An array of analyzers to generate the template for.
   * @returns The generated toml template string.
   */
  analyzersTemplate(allAnalyzers: RepoConfigInterface['analyzers']): string {
    // Returns a toml template string of analyzers section (including it's meta)
    return allAnalyzers
      .map((analyzer) => {
        const headerLines = ['[[analyzers]]', `name = ${JSON.stringify(analyzer.name)}`]
        if (this.CONFIG_MAP_ANALYZER_TYPE[analyzer.type] === AnalyzerAnalyzerType.Community) {
          headerLines.push('type = "community"')
        }
        if (!analyzer.enabled) {
          headerLines.push('enabled = false')
        }

        const header = headerLines.join(NEWLINE)

        const meta = [header]

        if (analyzer.meta) {
          const metaOptions = Object.keys(analyzer.meta)
            .map((metaAttribute: string) => {
              if (!analyzer.meta) return ''
              const val = analyzer.meta[metaAttribute] as string[] | string | number | boolean
              if (Array.isArray(val)) {
                if (val.length) {
                  return `${metaAttribute} = ${parseArray(val, 2)}`
                }
                return null
              }
              if (val || val === false || val === 0) {
                return `${metaAttribute} = ${JSON.stringify(val)}`
              }
              return null
            })
            .filter((opt) => opt) // Remove all empty values

          if (metaOptions.length) {
            meta.push(
              [
                '', // skip a line
                '[analyzers.meta]',
                ...metaOptions
              ].join(INDENT)
            )
          }
        }

        return meta.join(NEWLINE)
      })
      .join(NEWLINE.repeat(2))
  }

  /**
   * Generates a toml template string for the transformers section.
   *
   * @param allTransformers - An array of transformer objects.
   * @returns A toml template string.
   */
  transformersTemplate(allTransformers: RepoConfigInterface['transformers']): string {
    // Returns a toml template string of transformers section
    return allTransformers
      .map((transformer: TransformerInterface) => {
        const headerLines = ['[[transformers]]', `name = ${JSON.stringify(transformer.name)}`]
        if (!transformer.enabled) headerLines.push('enabled = false')

        return headerLines.join(NEWLINE)
      })
      .join(NEWLINE.repeat(2))
  }
}
