import { providerMetaMap, ProviderMeta, routerVcsMap } from '~/plugins/helpers/provider'
import {
  AutofixRunPullRequestStatus,
  AutofixRunStatus,
  CheckStatus,
  ComplianceCategory,
  RunStatus
} from '~/types/types'

const knownTagPatterns: Record<string, string> = {
  '^cwe': '#005980',
  '^owasp': '#ea580c',
  '^sans': '#005980',
  '^a[0-1][0-9]': '#ea580c'
}

const teamAvatars = [
  require('~/assets/images/default-avatars/team/team-1.webp'),
  require('~/assets/images/default-avatars/team/team-2.webp'),
  require('~/assets/images/default-avatars/team/team-3.webp'),
  require('~/assets/images/default-avatars/team/team-4.webp'),
  require('~/assets/images/default-avatars/team/team-5.webp'),
  require('~/assets/images/default-avatars/team/team-6.webp'),
  require('~/assets/images/default-avatars/team/team-7.webp'),
  require('~/assets/images/default-avatars/team/team-8.webp'),
  require('~/assets/images/default-avatars/team/team-9.webp')
]

const personAvatars = [
  require('~/assets/images/default-avatars/person/person-1.webp'),
  require('~/assets/images/default-avatars/person/person-2.webp'),
  require('~/assets/images/default-avatars/person/person-3.webp'),
  require('~/assets/images/default-avatars/person/person-4.webp'),
  require('~/assets/images/default-avatars/person/person-5.webp'),
  require('~/assets/images/default-avatars/person/person-6.webp'),
  require('~/assets/images/default-avatars/person/person-7.webp'),
  require('~/assets/images/default-avatars/person/person-8.webp'),
  require('~/assets/images/default-avatars/person/person-9.webp')
]

const knownTags: Record<string, string> = {
  django: '#092E20',
  eslint: '#4B32C3',
  flask: '#52575c',
  javascript: '#f7df1e',
  node: '#66cc33',
  react: '#61DBFB',
  nextjs: '#61DBFB',
  vue: '#41B883',
  nuxtjs: '#41B883',
  vite: '#646cff',
  security: '#d6435b',
  types: '#2449c1',
  typescript: '#3178c6'
}

export const zealPalette = [
  '#52575c',
  '#414141',
  '#6753b5',
  '#1e54c5',
  '#7a97fa',
  '#c97bd4',
  '#49f9cf',
  '#f977ff',
  '#2eb78b',
  '#d6435b',
  '#33cb9a',
  '#4568dc',
  '#da5565',
  '#f6d87c',
  '#23c4f8',
  '#2449c1',
  '#07aade'
]

/**
 * Return brand tag color, if exists, else picks color from internal color pallete
 *
 * @param tagName string
 * @returns string
 */
export function generateColorFromTag(tagName: string): string {
  if (knownTags[tagName]) return knownTags[tagName]

  const matchedTag = Object.keys(knownTagPatterns).find((pattern) =>
    new RegExp(pattern, 'i').test(tagName)
  )

  if (matchedTag) {
    return knownTagPatterns[matchedTag]
  }

  return zealPalette[tagName.length % zealPalette.length]
}

/**
 * Generate shade of a give color
 *
 * @param {string} hexColor - color to generate shades for
 * @param {number} magnitude - amount to change the color
 *
 * @return {string}
 */
export function newShade(hexColor: string, magnitude: number): string {
  const stdHexColor = hexColor.replace('#', '')
  if (stdHexColor.length === 6) {
    const decimalColor = parseInt(stdHexColor, 16)

    // Limit red, green and blue values to 0-255 range
    const red = Math.max(0, Math.min(255, (decimalColor >> 16) + magnitude))

    const green = Math.max(0, Math.min(255, (decimalColor & 0x0000ff) + magnitude))

    const blue = Math.max(0, Math.min(255, ((decimalColor >> 8) & 0x00ff) + magnitude))

    const color = `#${(green | (blue << 8) | (red << 16)).toString(16)}`

    if (color.length < 7) {
      return `${color}${new Array(7 - color.length).fill(0).join()}`
    }
    return color
  } else {
    return stdHexColor
  }
}

/**
 * Generate n shades of a given color
 *
 * @param {string} baseColor - base color to generate shades for
 * @param {number} numberOfShades - number of shades to generate
 *
 * @return {string[]}
 */
export function getColorShades(baseColor: string, numberOfShades: number): string[] {
  const stepFactor = Math.floor(140 / numberOfShades)
  return Array.from(Array(numberOfShades).keys()).map((step) =>
    newShade(baseColor, step * stepFactor)
  )
}

/**
 * Get icon for a given check status.
 *
 * @param {CheckStatus} status Status code of the check
 * @returns {string} icon to be used to represent the check status
 */
export function checkStatusIcon(status: CheckStatus): string {
  const types: Record<CheckStatus, string> = {
    [CheckStatus.Pass]: 'check',
    [CheckStatus.Fail]: 'x',
    [CheckStatus.Pend]: 'spin-loader',
    [CheckStatus.Timo]: 'timer-reset',
    [CheckStatus.Cncl]: 'alert-circle',
    [CheckStatus.Read]: 'circle-dot',
    [CheckStatus.Neut]: 'circle-dot',
    [CheckStatus.Atmo]: 'run-failed',
    [CheckStatus.Wait]: 'alarm-clock',
    [CheckStatus.Skip]: 'circle-slash'
  }
  return types[status] ?? types[CheckStatus.Pass]
}

/**
 * Get icon color for a given check status.
 *
 * @param {CheckStatus} status Status code of the check
 * @returns {string} icon color to be used to represent the check status
 */
export function checkStatusIconColor(status: CheckStatus): string {
  const types: Record<CheckStatus, string> = {
    [CheckStatus.Pass]: 'juniper',
    [CheckStatus.Fail]: 'cherry',
    [CheckStatus.Pend]: 'vanilla-400',
    [CheckStatus.Timo]: 'honey',
    [CheckStatus.Cncl]: 'honey',
    [CheckStatus.Read]: 'vanilla-400',
    [CheckStatus.Neut]: 'vanilla-400',
    [CheckStatus.Atmo]: 'vanilla-400',
    [CheckStatus.Wait]: 'vanilla-400',
    [CheckStatus.Skip]: 'slate-200'
  }
  return types[status] ?? types[CheckStatus.Pass]
}

/**
 * Get tag label for a given check status.
 *
 * @param {CheckStatus} status Status code of the check
 * @returns {string} label for the tag
 */
export function checkStatusTagLabel(status: CheckStatus): string {
  const types: Record<CheckStatus, string> = {
    [CheckStatus.Pass]: 'Passed',
    [CheckStatus.Fail]: 'Failing',
    [CheckStatus.Pend]: 'Running',
    [CheckStatus.Timo]: 'Timed out',
    [CheckStatus.Cncl]: 'Cancelled',
    [CheckStatus.Read]: 'Ready',
    [CheckStatus.Wait]: 'Waiting',
    [CheckStatus.Atmo]: 'Timed out',
    [CheckStatus.Neut]: 'Ready',
    [CheckStatus.Skip]: 'Skipped'
  }
  return types[status] ?? types[CheckStatus.Pass]
}

/**
 * Get icon for a given run status.
 *
 * @param {RunStatus} status Status code of the run
 * @returns {string} icon to be used to represent the run status
 */
export function runStatusIcon(status: RunStatus): string {
  const types: Record<RunStatus, string> = {
    [RunStatus.Pass]: 'check',
    [RunStatus.Fail]: 'x',
    [RunStatus.Pend]: 'spin-loader',
    [RunStatus.Timo]: 'timer-reset',
    [RunStatus.Cncl]: 'alert-circle',
    [RunStatus.Read]: 'circle-dot',
    [RunStatus.Skip]: 'circle-slash'
  }
  return types[status] ?? types[RunStatus.Pass]
}

/**
 * Get icon color for a given run status.
 *
 * @param {RunStatus} status Status code of the run
 * @returns {string} icon color to be used to represent the run status
 */
export function runStatusIconColor(status: RunStatus): string {
  const types: Record<RunStatus, string> = {
    [RunStatus.Pass]: 'juniper',
    [RunStatus.Fail]: 'cherry',
    [RunStatus.Pend]: 'vanilla-100',
    [RunStatus.Timo]: 'honey',
    [RunStatus.Cncl]: 'honey',
    [RunStatus.Read]: 'vanilla-400',
    [RunStatus.Skip]: 'slate-200'
  }
  return types[status] ?? types[RunStatus.Pass]
}

/**
 * Get tag label for a given run status.
 *
 * @param {RunStatus} status Status code of the run
 * @returns {string} label for the tag
 */
export function runStatusTagLabel(status: RunStatus, forSentence = false): string {
  const types: Record<RunStatus, string> = {
    [RunStatus.Pass]: 'Passed',
    [RunStatus.Fail]: 'Failing',
    [RunStatus.Pend]: 'Running',
    [RunStatus.Timo]: 'Timed out',
    [RunStatus.Cncl]: 'Cancelled',
    [RunStatus.Read]: 'Ready',
    [RunStatus.Skip]: 'Skipped'
  }

  const sentenceTypes: Record<RunStatus, string> = {
    [RunStatus.Pass]: 'Passed in',
    [RunStatus.Fail]: 'Failed after',
    [RunStatus.Pend]: 'Analysis in progress',
    [RunStatus.Timo]: 'Timed out after',
    [RunStatus.Cncl]: 'Cancelled after',
    [RunStatus.Read]: 'Completed in',
    [RunStatus.Skip]: 'Skipped'
  }

  if (forSentence) {
    return sentenceTypes[status] ?? sentenceTypes[RunStatus.Pass]
  }

  return types[status] ?? types[RunStatus.Pass]
}

/**
 * Get icon for a given autofix's PR status.
 *
 * @param {AutofixRunPullRequestStatus} status Status code of the autofix's PR
 * @returns {string} icon to be used to represent the PR status
 */
export function autofixRunPRStatusIcon(status: AutofixRunPullRequestStatus): string {
  const types: Record<AutofixRunPullRequestStatus, string> = {
    [AutofixRunPullRequestStatus.Pro]: 'git-pull-request',
    [AutofixRunPullRequestStatus.Prc]: 'git-pull-request',
    [AutofixRunPullRequestStatus.Prm]: 'git-pull-request',
    [AutofixRunPullRequestStatus.Pnc]: 'git-pull-request',
    [AutofixRunPullRequestStatus.Prf]: 'x',
    [AutofixRunPullRequestStatus.Prp]: 'spin-loader'
  }
  return types[status] ?? types[AutofixRunPullRequestStatus.Pnc]
}

/**
 * Get icon color for a given autofix's PR status.
 *
 * @param {AutofixRunPullRequestStatus} status Status code of the autofix's PR
 * @returns {string} icon color to be used to represent the PR status
 */
export function autofixRunPRStatusIconColor(status: AutofixRunPullRequestStatus): string {
  const types: Record<AutofixRunPullRequestStatus, string> = {
    [AutofixRunPullRequestStatus.Pro]: 'juniper',
    [AutofixRunPullRequestStatus.Prc]: 'cherry',
    [AutofixRunPullRequestStatus.Prm]: 'purple',
    [AutofixRunPullRequestStatus.Pnc]: 'honey',
    [AutofixRunPullRequestStatus.Prf]: 'cherry',
    [AutofixRunPullRequestStatus.Prp]: 'vanilla-100'
  }
  return types[status] ?? types[AutofixRunPullRequestStatus.Pnc]
}

/**
 * Get tag label for a given autofix's PR status.
 *
 * @param {AutofixRunPullRequestStatus} status Status code of the PR
 * @returns {string} label for the tag
 */
export function autofixRunPRStatusTagLabel(status: AutofixRunPullRequestStatus): string {
  const types: Record<AutofixRunPullRequestStatus, string> = {
    [AutofixRunPullRequestStatus.Pro]: 'open',
    [AutofixRunPullRequestStatus.Prc]: 'closed',
    [AutofixRunPullRequestStatus.Prm]: 'merged',
    [AutofixRunPullRequestStatus.Pnc]: 'not created',
    [AutofixRunPullRequestStatus.Prf]: 'failed',
    [AutofixRunPullRequestStatus.Prp]: 'in progress'
  }

  return types[status] ?? types[AutofixRunPullRequestStatus.Pnc]
}
/**
 * Get icon for a given autofix run's status.
 *
 * @param {AutofixRunStatus} status Status code of the run
 * @returns {string} icon to be used to represent the run status
 */
export function autofixRunStatusIcon(status: AutofixRunStatus): string {
  const types: Record<AutofixRunStatus, string> = {
    [AutofixRunStatus.Pass]: 'check',
    [AutofixRunStatus.Fail]: 'x',
    [AutofixRunStatus.Pend]: 'spin-loader',
    [AutofixRunStatus.Timo]: 'timer-reset',
    [AutofixRunStatus.Cncl]: 'alert-circle',
    [AutofixRunStatus.Stal]: 'stale'
  }

  return types[status] ?? types[AutofixRunStatus.Pend]
}

/**
 * Get icon color for a given autofix run's status.
 *
 * @param {AutofixRunStatus} status Status code of the run
 * @returns {string} icon color to be used to represent the run status
 */
export function autofixRunStatusIconColor(status: AutofixRunStatus): string {
  const types: Record<AutofixRunStatus, string> = {
    [AutofixRunStatus.Pass]: 'juniper',
    [AutofixRunStatus.Fail]: 'cherry',
    [AutofixRunStatus.Pend]: 'vanilla-100',
    [AutofixRunStatus.Timo]: 'honey',
    [AutofixRunStatus.Cncl]: 'vanilla-400',
    [AutofixRunStatus.Stal]: 'slate'
  }

  return types[status] ?? types[AutofixRunStatus.Pend]
}

/**
 * Get tag label for a given autofix's  status.
 *
 * @param {AutofixRunStatus} status Status code of the
 * @returns {string} label for the run tag
 */
export function autofixRunStatusTagLabel(status: AutofixRunStatus): string {
  const types: Record<AutofixRunStatus, string> = {
    [AutofixRunStatus.Pass]: 'completed',
    [AutofixRunStatus.Fail]: 'failed',
    [AutofixRunStatus.Pend]: 'running',
    [AutofixRunStatus.Timo]: 'timed out',
    [AutofixRunStatus.Cncl]: 'cancelled',
    [AutofixRunStatus.Stal]: 'stale'
  }

  return types[status] ?? types[AutofixRunStatus.Pend]
}

/**
 * Return default avatar from the ones available in assets
 *
 * @param {string} name - the key to select avatar from
 * @param {boolean} [forIndividual=true]
 * @returns {NodeRequire}
 */
export function getDefaultAvatar(name: string, forIndividual = true): NodeRequire {
  const imagesSrcArray = forIndividual ? personAvatars : teamAvatars
  if (typeof name === 'string') return imagesSrcArray[name.length % imagesSrcArray.length]

  return imagesSrcArray[0]
}

/**
 * Verifies if a given `parentCandidate` contains a `target` element.
 *
 * @param {HTMLElement} parentCandidate - Parent to find `target` in.
 * @param {HTMLElement} target - Element to find.
 * @returns {boolean} `true` if `target` is found in `parentCandidate`, else returns `false`.
 */
export function containsElement(parentCandidate: HTMLElement, target: HTMLElement): boolean {
  return Boolean(
    parentCandidate && (target === parentCandidate || parentCandidate.contains(target))
  )
}

/**
 * Function to generate correct copy text for a Pull Request depending on the VCS provider.
 *
 * @param {ProviderMeta['shortcode']} providerShortcode - Shortcode of the VCS provider
 * @returns {string} string containing pull request's correct copy text
 */
export function prCopyText(providerShortcode: ProviderMeta['shortcode']): string {
  const copyText = {
    [providerMetaMap.gh.shortcode]: 'pull request',
    [providerMetaMap.ghe.shortcode]: 'pull request',
    [providerMetaMap.gl.shortcode]: 'merge request',
    [providerMetaMap.bb.shortcode]: 'pull request',
    [providerMetaMap.gsr.shortcode]: 'pull request'
  }

  return copyText[providerShortcode] ?? 'pull request'
}

/**
 * Remove hyphens from tag slugs except for cwe tags
 *
 * @param  {string} tag
 * @returns {string}
 */
export function deslugifyTag(tag: string): string {
  const cweRegex = /^(cwe-)[0-9]+$/i

  if (cweRegex.test(tag)) {
    return String(tag)
  }

  return String(tag).replace(/-/g, ' ')
}

/**
 * Return the icon name for a given issue category
 *
 * @param {string} issueCategory
 * @return {string}
 */
export function getIcon(issueCategory: string): string {
  return issueCategory === 'secrets' ? 'secret' : issueCategory
}

/**
 * Return state to be used in ZTagV2 based on issue category
 *
 * @param {string} issueCategory
 * @return {string}
 */
export function getTagState(issueCategory: ComplianceCategory): string {
  const tagClass: Record<ComplianceCategory, string> = {
    [ComplianceCategory.Required]: 'honey',
    [ComplianceCategory.Advisory]: 'juniper',
    [ComplianceCategory.Mandatory]: 'cherry'
  }

  return tagClass[issueCategory] ?? ''
}

export function mrOrPr(provider: routerVcsMap, shorthand = false) {
  if (shorthand) return provider === 'gl' ? 'MR' : 'PR'
  return provider === 'gl' ? 'Merge request' : 'Pull request'
}
