import { render, staticRenderFns } from "./repository.vue?vue&type=template&id=2baa4fd4&"
import script from "./repository.vue?vue&type=script&lang=ts&"
export * from "./repository.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainSidebar: require('/app/components/Layout/Sidebar/MainSidebar.vue').default,LoggedOutSidebar: require('/app/components/Layout/LoggedOutSidebar.vue').default,RepoHeader: require('/app/components/Layout/RepoHeader.vue').default,ZButton: require('/app/components/zeal/ZButton/ZButton.vue').default,ZAlert: require('/app/components/zeal/ZAlert/ZAlert.vue').default,RepoInactive: require('/app/components/RepoStates/RepoInactive.vue').default,RepoError: require('/app/components/RepoStates/RepoError.vue').default,RepoEmpty: require('/app/components/RepoStates/RepoEmpty.vue').default,RepoTimeout: require('/app/components/RepoStates/RepoTimeout.vue').default,RepoCommunityWaiting: require('/app/components/RepoStates/RepoCommunityWaiting.vue').default,RepoWaiting: require('/app/components/RepoStates/RepoWaiting.vue').default,Palette: require('/app/components/ToitBar/Palette.vue').default})
