
import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component({})
export default class SidebarItem extends Vue {
  @Prop()
  icon?: string

  @Prop()
  iconColor?: string

  @Prop({ default: false })
  active!: boolean

  @Prop({ default: false })
  isCollapsed!: boolean

  @Prop({ default: '' })
  to!: string

  get linkAttrs() {
    if (!this.to) {
      return {
        is: 'div'
      }
    }

    if (this.isExternalLink) {
      return {
        is: 'a',
        href: this.to
      }
    }

    return {
      is: 'nuxt-link',
      to: this.to
    }
  }

  get isExternalLink(): boolean {
    return (
      this.to.startsWith('mailto:') ||
      this.to.startsWith('https://') ||
      this.to.startsWith('http://')
    )
  }
}
