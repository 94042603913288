
import Vue from 'vue'
import ZIcon from '../ZIcon/ZIcon.vue'

const DEFAULT_BORDER_ACTIVE_COLOR = 'juniper'

export default Vue.extend({
  name: 'ZTab',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    action: {
      type: Function
    },
    removeIndicatorStyles: {
      type: Boolean,
      default: false
    },
    borderActiveColor: {
      type: String,
      default: DEFAULT_BORDER_ACTIVE_COLOR
    }
  },
  computed: {
    selected(): string {
      return String(this.isActive)
    },
    borderActive(): string {
      return `border-${this.borderActiveColor}`
    }
  }
})
