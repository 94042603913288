import { Route } from 'vue-router'

type EncodeRouteParams = Partial<Route>

export function encodeRoute({ name, params, query }: EncodeRouteParams): string {
  return encodeURIComponent(
    JSON.stringify({
      name,
      params,
      query
    })
  )
}
