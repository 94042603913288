
import { Component, Prop, mixins } from 'nuxt-property-decorator'

// types
import ActiveUserMixin, { DashboardContext } from '~/mixins/activeUserMixin'
import ContextMixin from '~/mixins/contextMixin'
import { getDefaultAvatar } from '~/utils/ui'

/**
 * Context switcher component
 */
@Component({
  methods: {
    getDefaultAvatar
  }
})
export default class ContextSwitcher extends mixins(ActiveUserMixin, ContextMixin) {
  searchCandidate = ''

  @Prop()
  isCollapsed: boolean

  /**
   * Fetch hook for context switcher
   *
   * @returns {Promise<void>}
   */
  async fetch(): Promise<void> {
    await Promise.all([
      this.fetchContext(),
      ...(this.$config.onPrem ? [this.fetchIsViewerSuperadmin()] : [])
    ])
  }

  /**
   * Returns a boolean indicating if `context` is currently active
   *
   * @param {DashboardContext} context - The context to check
   * @returns {boolean}
   */
  isActive(context: DashboardContext): boolean {
    return (
      (context.login === this.activeDashboardContext.login ||
        context.team_name === this.activeDashboardContext.team_name) &&
      context.vcs_provider === this.activeDashboardContext.vcs_provider
    )
  }

  /**
   * Returns the icon name for the given VCS provider shortcode
   *
   * @param {string} vcsShortcode - Provider shortcode
   * @returns {string}
   */
  providerIcon(vcsShortcode: string): string {
    return this.$providerMetaMap[vcsShortcode]?.icon ?? ''
  }

  /**
   * Checks if the given `contextName` matches the current `searchCandidate`
   *
   * @param {string} contextName - The context name to check against the current search query
   * @returns {boolean}
   */
  filterByQuery(context: DashboardContext): boolean {
    const contextName = context.team_name || context.login
    return this.searchCandidate
      ? contextName.toLowerCase().includes(this.searchCandidate.toLowerCase())
      : true
  }

  /**
   * Getter for available contexts, divided by type (team / user) and filtered by the search query
   *
   * @returns {Array<Record<string, Array<DashboardContext>>>}
   */
  get contextsByType() {
    if (this.searchCandidate) {
      const searchResults = this.viewer.dashboardContext.filter(this.filterByQuery)
      return searchResults.length
        ? [
            {
              type: 'all',
              contexts: searchResults
            }
          ]
        : []
    }

    const teamAccounts = this.viewer.dashboardContext.filter(
      (context: Record<string, string>) => context.type === 'team'
    )
    const personalAcounts = this.viewer.dashboardContext.filter(
      (context: Record<string, string>) => context.type === 'user'
    )

    const contexts = []

    if (teamAccounts.length)
      contexts.push({
        type: 'teams',
        contexts: teamAccounts
      })
    if (personalAcounts.length)
      contexts.push({
        type: 'personal',
        contexts: personalAcounts
      })
    return contexts
  }

  get showAddAccountBtn(): boolean {
    // Check for superadmin status only if on prem, else true
    const canUserAddAccount = this.$config.onPrem ? this.isViewerSuperadmin : true

    return this.contextsByType.length > 0 && !this.searchCandidate && canUserAddAccount
  }

  /**
   * Returns a class string for the menu trigger depending on its open state
   *
   * @param {boolean} isOpen - The state of the menu trigger
   */
  getTriggerButtonStyle(isOpen: boolean) {
    return this.isCollapsed ? 'hover:opacity-75 px-0' : isOpen ? 'bg-ink-200' : 'hover:bg-ink-200'
  }

  /**
   * Resets the search query
   *
   * @returns {void}
   */
  resetSearch() {
    this.searchCandidate = ''
  }

  /**
   * Function to open/close the menu
   *
   * @callback ToggleCallback
   * @returns {void}
   */

  /**
   * Handles the menu toggle and resets the search query
   *
   * @param {ToggleCallback} toggle - The callback to toggle the menu
   * @returns {void}
   */
  handleToggle(toggle: () => void) {
    toggle()
    this.resetSearch()
  }
}
