
import { Vue, Prop, Component } from 'nuxt-property-decorator'

@Component
export default class EmptyState extends Vue {
  @Prop({ required: false, default: '' })
  title: string

  @Prop({ required: false, default: '' })
  subtitle: string

  // Prop to specify if the updated version of empty-states needs to be used
  @Prop({ default: false, type: Boolean })
  useV2?: boolean

  @Prop({ default: require('~/assets/images/ui-states/empty.webp') })
  webpImagePath: () => any

  @Prop({ default: require('~/assets/images/ui-states/empty.gif') })
  pngImagePath: () => any

  @Prop({ default: '' })
  svgImagePath: () => any

  @Prop({ default: false, type: Boolean })
  showBorder: boolean

  @Prop()
  imageWidth: string

  @Prop({ default: 'This page has no data' })
  altText: string

  @Prop({ default: 'max-w-2xs', type: String })
  contentWidth: string

  @Prop({ default: 'px-3.5 py-12 md:px-12 md:py-19', type: String })
  padding: string
}
