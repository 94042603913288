export interface Identifiable {
  id: string
  [key: string]: unknown
}

/**
 * Merges or replaces properties of two objects based on specified keys.
 * If the objects have different IDs, the new object is returned.
 * Otherwise, it merges the two objects, with deep merging for specified keys.
 *
 * @template T - The type of the objects being merged.
 * @param oldObj - The original object.
 * @param newObj - The new object.
 * @param deepMergeKeys - The keys to be merged.
 * @returns The merged or replaced object.
 */
export function mergeOrReplaceObjects<T extends Identifiable>(
  oldObj: T,
  newObj: T,
  deepMergeKeys: (keyof T)[]
): T {
  if (oldObj.id && newObj.id && oldObj.id !== newObj.id) {
    return newObj
  } else {
    const mergedObjs: Partial<T> = {}
    for (const key of deepMergeKeys) {
      if (newObj[key] && typeof oldObj[key] === 'object' && typeof newObj[key] === 'object') {
        mergedObjs[key] = { ...(oldObj[key] as object), ...(newObj[key] as object) } as T[keyof T]
      }
    }
    return Object.assign({}, oldObj, newObj, mergedObjs)
  }
}
