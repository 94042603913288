
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({})
export default class HeroLayout extends Vue {
  @Prop({ default: false, type: Boolean })
  hideLogo: boolean

  @Prop({ default: 'sm:max-w-sm', type: String })
  bodyMaxWidth: string

  @Prop({ default: true, type: Boolean })
  centerHero: boolean

  @Prop({ type: String })
  connectionAsset: string

  @Prop({ type: String })
  connectionAssetLabel: string

  @Prop({
    default:
      'linear-gradient(180deg, rgba(69, 175, 220, 0.6) 64.4%, rgba(59, 100, 236, 0.6) 76.78%, rgba(69, 104, 220, 0) 100%)',
    type: String
  })
  bgGradient: string

  get cssVars() {
    return {
      '--bg-gradient': this.bgGradient
    }
  }
}
