
import { Component, Prop, mixins } from 'nuxt-property-decorator'
import { Repository } from '~/types/types'
import RepoDetailMixin from '~/mixins/repoDetailMixin'

@Component({
  name: 'RepoTimeout'
})
export default class RepoTimeout extends mixins(RepoDetailMixin) {
  @Prop()
  id: Repository['id']

  @Prop({ default: false })
  activateAnalysisLoading: boolean
}
