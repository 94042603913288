var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex w-full items-center space-x-2 rounded-sm p-1 outline-none",class:[
    _vm.borderStyles,
    `bg-${_vm.backgroundColor}`,
    {
      'cursor-not-allowed text-slate': _vm.disabled,
      'cursor-not-allowed text-vanilla-400': _vm.readOnly,
      'text-vanilla-300': !_vm.disabled && !_vm.readOnly,
      'h-6 space-x-1 text-xs leading-loose': _vm.size === 'x-small',
      'h-8 space-x-1 text-xs leading-loose': _vm.size === 'small',
      'h-10 space-x-2 text-sm leading-8': _vm.size === 'medium',
      'h-12 space-x-2.5 text-base leading-9': _vm.size === 'large',
      'h-13 space-x-3 text-lg leading-9': ['x-large', 'xlarge'].includes(_vm.size),
      'px-1.5 py-0.5': _vm.size === 'x-small' && !_vm.padding,
      'px-2 py-1': _vm.size === 'small' && !_vm.padding,
      'px-3 py-2': _vm.size === 'medium' && !_vm.padding,
      'px-4 py-2.5': _vm.size === 'large' && !_vm.padding,
      'px-4 py-3': ['x-large', 'xlarge'].includes(_vm.size) && !_vm.padding,
      'pr-1': _vm.$slots.right && !_vm.$slots.left && !_vm.padding,
      'pl-1': _vm.$slots.left && !_vm.$slots.right && !_vm.padding,
      [_vm.padding]: _vm.padding
    }
  ]},[_vm._t("left"),_vm._v(" "),_c('input',{directives:[{name:"debounce",rawName:"v-debounce:[debounceDelay]",value:({ func: _vm.updateDebounce, event: 'input' }),expression:"{ func: updateDebounce, event: 'input' }",arg:_vm.debounceDelay}],ref:"input",staticClass:"flex w-full flex-grow overflow-hidden overflow-ellipsis bg-transparent outline-none",class:{
      'cursor-not-allowed': _vm.disabled || _vm.readOnly
    },attrs:{"aria-label":_vm.label,"name":_vm.name,"type":_vm.type,"max":_vm.max,"maxlength":_vm.maxLength,"min":_vm.min,"minlength":_vm.minLength,"multiple":_vm.multiple,"required":_vm.required,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readOnly,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateSelf($event.target.value)},"blur":_vm.blurHandler,"focus":_vm.focusHandler,"keydown":_vm.keydownHandler,"keyup":_vm.keyupHandler,"invalid":_vm.invalidHandler}}),_vm._v(" "),_vm._t("right",function(){return [(_vm.clearable)?_c('z-icon',{staticClass:"cursor-pointer",attrs:{"icon":"x","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.clearInput.apply(null, arguments)}}}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }