
export default {
  name: 'ZBreadcrumbItem',
  props: {
    isCurrent: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      separator: '',
      index: 0
    }
  },
  computed: {
    current() {
      return this.isCurrent || this.index === this.$parent.length - 1
    }
  },
  mounted() {
    this.separator = this.$parent.separator
  }
}
