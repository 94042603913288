
import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component({})
export default class BaseState extends Vue {
  @Prop()
  title: string

  @Prop({ default: 'min-h-92 max-h-screen' })
  heightClass: string

  @Prop({ default: 'p-12' })
  spacingClass: string
}
