import { render, staticRenderFns } from "./RepoHeader.vue?vue&type=template&id=909c4e62&scoped=true&"
import script from "./RepoHeader.vue?vue&type=script&lang=ts&"
export * from "./RepoHeader.vue?vue&type=script&lang=ts&"
import style0 from "./RepoHeader.vue?vue&type=style&index=0&id=909c4e62&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "909c4e62",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZButton: require('/app/components/zeal/ZButton/ZButton.vue').default,ZIcon: require('/app/components/zeal/ZIcon/ZIcon.vue').default,ZTag: require('/app/components/zeal/ZTag/ZTag.vue').default,ZTagV2: require('/app/components/zealv2/ZTagV2.vue').default,ZTab: require('/app/components/zeal/ZTab/ZTab.vue').default})
