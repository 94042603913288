
import Vue from 'vue'
import ZIcon from '../ZIcon/ZIcon.vue'
import debounceDirective from '~/utils/directives/debounce'

export default Vue.extend({
  name: 'ZInput',

  directives: {
    debounce: debounceDirective
  },
  props: {
    name: {
      default: '',
      type: String
    },
    value: {
      default: '',
      type: [String, Number]
    },
    label: {
      default: '',
      type: String
    },
    type: {
      default: 'text',
      type: String
    },
    padding: {
      type: String,
      default: undefined
    },
    max: {
      type: [String, Number],
      default: undefined
    },
    min: {
      type: [String, Number],
      default: undefined
    },
    placeholder: {
      default: 'Enter a value',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    debounceDelay: {
      default: 350,
      type: Number
    },
    icon: {
      type: String,
      default: undefined
    },
    clearable: {
      type: Boolean
    },
    autocomplete: {
      type: String,
      default: undefined
    },
    size: {
      default: 'medium',
      type: String,
      validator(val: string) {
        return ['x-small', 'small', 'medium', 'large', 'x-large'].includes(val)
      }
    },
    backgroundColor: {
      type: String,
      default: 'ink-400'
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    errorBorderColor: {
      type: String,
      default: 'cherry'
    },
    maxLength: {
      type: [String, Number],
      default: undefined
    },
    minLength: {
      type: [String, Number],
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    validateOnBlur: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      invalidState: false
    }
  },
  computed: {
    borderStyles(): string {
      if (this.isInvalid || this.invalidState) {
        return 'border border-cherry'
      }

      if (this.showBorder) {
        return 'focus-within:border-vanilla-400 border border-ink-100'
      }

      return ''
    }
  },
  methods: {
    focus(): void {
      ;(this.$refs.input as HTMLInputElement).focus()
    },
    updateSelf(value: string): void {
      this.$emit('input', value)
    },
    updateDebounce(value: unknown): void {
      this.$emit('debounceInput', value)
    },
    clearInput() {
      this.updateSelf('')
      this.updateDebounce('')
    },
    blurHandler(e: FocusEvent) {
      this.$emit('blur', e)
      if (this.validateOnBlur) {
        const eventTarget = e.target as HTMLInputElement
        this.invalidState = !eventTarget.checkValidity()
      }
    },
    focusHandler(e: FocusEvent) {
      this.$emit('focus', e)
    },
    keyupHandler(e: KeyboardEvent) {
      this.$emit('keyup', e)
    },
    keydownHandler(e: KeyboardEvent) {
      this.$emit('keydown', e)
    },
    invalidHandler(e: Event) {
      if (this.validateOnBlur) {
        this.invalidState = true
      }
      this.$emit('invalid', e)
    }
  }
})
