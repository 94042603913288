import Vue from "vue";
import WebSocketManager from "./WebSocketManager";
const reconnectInterval = Number("") || 1e3;
const urlFromOptions = "";
export default ({app}, inject) => {
  const runtimeConfig = app.$config && app.$config.websocket || {};
  const url = runtimeConfig.url || urlFromOptions;
  if (!url) {
    return console.error("WebSocket connection URL is required. Please specify it via options or runtime configuration.");
  }
  const emitter = new Vue();
  const manager = new WebSocketManager(url, emitter, reconnectInterval);
  inject("socket", emitter);
  inject("socketManager", manager);
};
