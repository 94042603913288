
import { Vue, Component, Prop } from 'nuxt-property-decorator'

interface PrimaryAction {
  label: string
  icon: string
  action: Function
}

@Component({})
export default class Toast extends Vue {
  @Prop({ default: '' })
  message!: string

  @Prop({ default: '' })
  type!: string

  @Prop({ default: true })
  progress!: boolean

  @Prop({ default: 2 })
  timeout!: number

  @Prop({ default: true })
  autoClose!: boolean

  @Prop({ default: 'bg-ink-200' })
  classToast!: string

  @Prop({ default: 'text-vanilla-100' })
  classTitle!: string

  @Prop()
  classMessage!: string

  @Prop({})
  classTimeout!: string

  @Prop({ default: () => {} })
  defaults!: object

  @Prop({
    default: () => ({ label: '', action: () => {} })
  })
  primary!: PrimaryAction

  public active = false
  public interval: NodeJS.Timeout
  public timeLeft = 0
  public speed = 100

  mounted(): void {
    this.active = true
    if (this.timeout > 0 && this.autoClose) {
      this.timeLeft = this.timeout * 1000
      setTimeout(this.destroy, this.timeLeft)
    }
  }

  get classToastAll(): Array<string> {
    if (this.classToast) return [this.classToast]
    return []
  }

  public removeElement(el: Element): void {
    if (el) {
      if (typeof el.remove !== 'undefined') el.remove()
      else el.parentNode?.removeChild(el)
    }
  }

  public destroy(): void {
    this.active = false
    setTimeout(() => {
      this.$destroy()
      this.removeElement(this.$el)
    }, 1000)
    this.$emit('destroy')
  }

  public primaryAction() {
    this.destroy()
    this.primary.action()
  }
}
