
import { Component, mixins } from 'nuxt-property-decorator'

import ActiveUserMixin, { DashboardContext } from '~/mixins/activeUserMixin'
import ContextMixin from '~/mixins/contextMixin'
import AuthMixin from '~/mixins/authMixin'
import { TeamPerms } from '~/types/permTypes'
import { getDefaultAvatar } from '~/utils/ui'

const FREE_PLAN_SLUG = 'free'

@Component({
  methods: { getDefaultAvatar }
})
export default class DashboardHeader extends mixins(ActiveUserMixin, ContextMixin, AuthMixin) {
  get planName(): string {
    return (this.activeDashboardContext as DashboardContext).subscribed_plan_info?.name
  }

  get hasPaidPlan(): boolean {
    return (
      (this.activeDashboardContext as DashboardContext).subscribed_plan_info?.slug !==
      FREE_PLAN_SLUG
    )
  }

  get canVisitBillingPage(): boolean {
    if (this.teamPerms.permission && this.activeOwner === this.$route.params.owner) {
      return this.$gateKeeper.team(
        [
          TeamPerms.CHANGE_PLAN,
          TeamPerms.UPDATE_SEATS,
          TeamPerms.DELETE_TEAM_ACCOUNT,
          TeamPerms.UPDATE_BILLING_DETAILS
        ],
        this.teamPerms.permission
      )
    }
    return false
  }

  get repoVCSIcon(): string {
    const provider = this.activeDashboardContext.vcs_provider
    return this.$providerMetaMap[provider]?.icon ?? ''
  }
}
