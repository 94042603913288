import { render, staticRenderFns } from "./DashboardHeader.vue?vue&type=template&id=d66bbdee&"
import script from "./DashboardHeader.vue?vue&type=script&lang=ts&"
export * from "./DashboardHeader.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZAvatar: require('/app/components/zeal/ZAvatar/ZAvatar.vue').default,ZTag: require('/app/components/zeal/ZTag/ZTag.vue').default})
