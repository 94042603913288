import { render, staticRenderFns } from "./user.vue?vue&type=template&id=ba3c43d2&"
import script from "./user.vue?vue&type=script&lang=ts&"
export * from "./user.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Sidebar: require('/app/components/Discover/Layout/Sidebar.vue').default,MobileNav: require('/app/components/Layout/MobileNav.vue').default,UserHeader: require('/app/components/Layout/UserHeader.vue').default})
