
import { Component, mixins } from 'nuxt-property-decorator'

import ActiveUserMixin from '~/mixins/activeUserMixin'
import AuthMixin from '~/mixins/authMixin'

import { getDefaultAvatar } from '~/utils/ui'

/**
 * Header for the user layout
 */
@Component({
  methods: { getDefaultAvatar }
})
export default class UserHeader extends mixins(ActiveUserMixin, AuthMixin) {}
