
import { Component, Prop, mixins } from 'nuxt-property-decorator'

// mixins
import ActiveUserMixin from '~/mixins/activeUserMixin'
import AuthMixin from '~/mixins/authMixin'

import { getDefaultAvatar } from '~/utils/ui'

@Component({
  methods: { getDefaultAvatar }
})
export default class UserMenu extends mixins(ActiveUserMixin, AuthMixin) {
  @Prop()
  isCollapsed: boolean

  showTooltip = false

  /**
   * Mounted hook for Vue component
   *
   * @returns {void}
   */
  mounted(): void {
    const username = this.$refs.username as Element
    if (username) {
      this.showTooltip = username.scrollWidth >= username.clientWidth
    }
  }
}
