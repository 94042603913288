
import { Vue, Prop, Component } from 'nuxt-property-decorator'

/**
 * Component for displaying the picture in EmptyState.vue
 */
@Component
export default class EmptyStatePicture extends Vue {
  @Prop()
  webpImagePath: () => any

  @Prop({ required: true })
  pngImagePath: () => any

  @Prop()
  svgImagePath: () => any

  @Prop({ default: 'w-28' })
  width: string

  @Prop()
  height: string

  @Prop({ default: 'This page has no data' })
  altText: string

  @Prop({ default: 'mx-auto' })
  imageClasses: string
}
