
import { Vue, Component } from 'vue-property-decorator'

/**
 * 500 Error Page
 */
@Component({
  components: {}
})
export default class FiveHundred extends Vue {}
