
import { Prop, mixins, Watch, Component } from 'nuxt-property-decorator'

import ActiveUserMixin from '~/mixins/activeUserMixin'
import RepoListMixin from '~/mixins/repoListMixin'
import { Repository, RepositoryKindChoices } from '~/types/types'

@Component({})
export default class SidebarRecentlyActive extends mixins(ActiveUserMixin, RepoListMixin) {
  @Prop({ default: false })
  isCollapsed: boolean

  public dropdownCollapsed = false

  async fetch(): Promise<void> {
    await this.fetchActiveUser()
    await this.fetchRepos()
  }

  mounted() {
    this.$socket.$on('repo-onboarding-completed', () => {
      this.fetchRepos(true)
    })
    const { provider, owner } = this.$route.params

    this.dropdownCollapsed =
      (this.$localStore.get('ui-state', `is-sidebar-collapsed-${provider}-${owner}`) as boolean) ??
      true
  }

  beforeDestroy() {
    this.$socket.$off('repo-onboarding-completed')
  }

  async fetchRepos(refetch = false): Promise<void> {
    await this.fetchActiveAnalysisRepoList({
      login: this.activeOwner,
      provider: this.activeProvider,
      refetch
    })
  }

  @Watch('activeDashboardContext.id')
  refetchRepos(): void {
    this.fetchRepos(true).catch(() => {})
  }

  @Watch('dropdownCollapsed')
  updateLocalState(): void {
    const { provider, owner } = this.$route.params

    if (process.client)
      this.$localStore.set(
        'ui-state',
        `is-sidebar-collapsed-${provider}-${owner}`,
        this.dropdownCollapsed
      )
  }

  get repoList(): Array<Record<string, unknown>> {
    if (this.repoWithActiveAnalysis) {
      return this.repoWithActiveAnalysis.map(
        ({ id, displayName, isPrivate, kind, name, ownerLogin }) => {
          const icon = this.getIcon({ isPrivate, kind })

          return {
            id,
            displayName,
            name,
            owner: ownerLogin,
            icon,
            isActive: false
          }
        }
      )
    }
    return []
  }

  buildRoute(repo: Record<string, unknown>): string {
    return `/${this.activeProvider}/${repo.owner ?? this.activeOwner}/${repo.name}`
  }

  getIcon({ isPrivate, kind }: Pick<Repository, 'isPrivate' | 'kind'>): string {
    if (kind === RepositoryKindChoices.Subrepo) {
      return isPrivate ? 'folder-locked' : 'folder-globe'
    }

    return isPrivate ? 'z-lock' : 'globe'
  }

  toggleDropdown(): void {
    if (this.repoList.length > 0) {
      this.dropdownCollapsed = !this.dropdownCollapsed
    }
  }

  get isActive(): boolean {
    if (!this.$route.name) return false

    return (
      this.$route.name === 'provider-owner-repo' ||
      this.$route.name?.startsWith('provider-owner-repo-')
    )
  }

  public getRoute(params: string): string {
    return `/${this.activeProvider}/${this.activeOwner}/${params}`
  }
}
