
import { Component, mixins, Watch } from 'nuxt-property-decorator'

import AuthMixin from '~/mixins/authMixin'
import InstallAutofixMixin from '~/mixins/installAutofixMixin'
import PaletteMixin from '~/mixins/paletteMixin'
import PortalMixin from '~/mixins/portalMixin'
import RepoDetailMixin from '~/mixins/repoDetailMixin'
import RoleAccessMixin from '~/mixins/roleAccessMixin'
import { CONFIG_MAP_ANALYZER_TYPE } from '~/mixins/tomlGeneratorMixin'
import { RepoConfigInterface } from '~/store/repository/detail'

import { AnalyzerAnalyzerType, RepositoryKindChoices, RunStatus } from '~/types/types'

/**
 * Layout file for `repository` views.
 */
@Component({
  middleware: ['hidePrivateRepo', 'protectMonorepoRoutes'],
  head: {
    bodyAttrs: {
      class: 'antialiased stroke-2 hide-scroll'
    }
  }
})
export default class RepositoryLayout extends mixins(
  AuthMixin,
  InstallAutofixMixin,
  RepoDetailMixin,
  PortalMixin,
  RoleAccessMixin,
  PaletteMixin
) {
  private pollingId = 0

  activateAnalysisLoading = false

  /**
   * Fetch hook for the repository layout.
   * Fetches:
   * - Base repo details
   *
   * @returns {Promise<void>}
   */
  async fetch(): Promise<void> {
    try {
      await this.fetchBasicRepoDetails(this.baseRouteParams)
    } catch (e) {
      this.$logErrorAndToast(e as Error, 'There was a problem loading this repository.')
    }
  }

  /**
   * Refetches base repo details and widgets for a repository.
   *
   * @returns {Promise<void>}
   */
  async refetchData(): Promise<void> {
    const args = {
      ...this.baseRouteParams,
      refetch: true
    }

    await Promise.all([this.fetchBasicRepoDetails(args), this.fetchWidgets(args)])
  }

  /**
   * Mounted hook for repository layout, initializes handlers for socket events.
   *
   * @returns {void}
   */
  mounted(): void {
    this.$socket.$on('repo-analysis-updated', this.refetchData)
    this.$socket.$on('repo-config-committed', this.refetchData)
    this.$socket.$on('repo-config-updated', this.refetchData)
    this.$socket.$on('autofix-installation-complete', this.refetchData)
    this.setPaletteEvent()
    this.registerCommands()
  }

  /**
   * BeforeDestroy hook for repository layout, removes handlers for socket events.
   *
   * @returns {void}
   */
  beforeDestroy(): void {
    this.$socket.$off('repo-analysis-updated', this.refetchData)
    this.$socket.$off('repo-config-committed', this.refetchData)
    this.$socket.$off('repo-config-updated', this.refetchData)
    this.$socket.$off('autofix-installation-complete', this.refetchData)
    clearTimeout(this.pollingId)
    this.removePaletteEvent()
  }

  /**
   * Remove refetch events when repo route param changes.
   *
   * @returns {void}
   */
  @Watch('$route.params.repo')
  removeRefetchEvents(): void {
    this.$root.$off('refetchCheck')
  }

  get isAnalyzed(): boolean {
    return Boolean(this.repository?.latestAnalysisRun?.id)
  }

  get repoHasCode(): boolean {
    return Boolean(this.repository.defaultBranchName)
  }

  get hasLastRunTimedOut(): boolean {
    return this.repository?.lastRun?.status === RunStatus.Timo
  }

  get allowedOnBroken(): boolean {
    if (this.repository.kind === RepositoryKindChoices.Monorepo) {
      return true
    }

    if (this.repository.errorCode === 3003) {
      // For ad-hoc runs
      return true
    }

    const allowedRoutes = [
      'provider-owner-repo-settings',
      'provider-owner-repo-generate-config',
      'provider-owner-repo-history',
      'provider-owner-repo-transform',
      'provider-owner-repo-run'
    ]
    const matchResults = allowedRoutes.map((name) => {
      if (this.$route.name) {
        return this.$route.name.startsWith(name)
      }
      return false
    })

    return matchResults.indexOf(true) > -1 ? true : false
  }

  get isRepoWaiting(): boolean {
    return (
      !this.isAnalyzed &&
      !this.allowedOnBroken &&
      this.$fetchState &&
      !this.$fetchState.pending &&
      !this.repository.errorCode &&
      this.repoHasCode &&
      !this.hasLastRunTimedOut
    )
  }

  get waitingOnCommunityAnalyzers() {
    if (this.isRepoWaiting) {
      return (this.repository.config as RepoConfigInterface)?.analyzers?.every(
        (analyzerConfig) =>
          CONFIG_MAP_ANALYZER_TYPE[analyzerConfig.type] === AnalyzerAnalyzerType.Community
      )
    }
    return false
  }

  /**
   * Watcher function to activate polling a repo if it's in waiting state.
   * Triggers immediately!
   *
   * @param {boolean} newIsRepoWaiting
   * @returns {Promise<void>}
   */
  @Watch('isRepoWaiting', { immediate: true })
  triggerPoll(newIsRepoWaiting: boolean): void {
    clearTimeout(this.pollingId)
    if (newIsRepoWaiting) {
      this.pollRepo()
    }
  }

  /**
   * Polling function that polls the status of a repo and keeps polling if the repo is in waiting state (first run).
   *
   * @returns {void}
   */
  pollRepo(): void {
    const POLLING_INTERVAL = 5000
    if (process.client) {
      this.pollingId = window.setTimeout(async () => {
        await this.pollRepoStatus(this.baseRouteParams)
        if (this.isRepoWaiting) {
          this.pollRepo()
        }
      }, POLLING_INTERVAL)
    }
  }

  /**
   * Register repo level commands
   *
   * @return {any}
   */
  registerCommands() {
    this.$palette.registerCommands(
      [
        {
          id: 'open-repo-issues',
          label: 'Issues',
          icon: 'flag',
          scope: 'repo',
          condition: (route) => {
            return route.name ? !route.name?.startsWith('provider-owner-repo-issues') : true
          },
          keywords: ['issues', 'bugs', 'risks'],
          action: () => {
            this.$router.push(this.$generateRoute(['issues']))
          }
        },
        {
          id: 'open-repo-autofix',
          label: 'Autofix',
          icon: 'autofix',
          keywords: ['autofix', 'fix'],
          scope: 'repo',
          condition: (route) => {
            return route.name ? !route.name?.startsWith('provider-owner-repo-autofix') : true
          },
          action: () => {
            this.$router.push(this.$generateRoute(['autofix']))
          }
        },
        {
          id: 'open-repo-metrics',
          label: 'Metrics',
          icon: 'bar-chart',
          keywords: ['metrics', 'stats', 'numbers', 'charts'],
          scope: 'repo',
          condition: (route) => {
            return route.name ? !route.name?.startsWith('provider-owner-repo-metrics') : true
          },
          action: () => {
            this.$router.push(this.$generateRoute(['metrics']))
          }
        },
        {
          id: 'open-repo-run-history',
          label: 'Analysis history',
          icon: 'history',
          keywords: ['metrics', 'stats', 'numbers', 'charts'],
          scope: 'repo',
          condition: (route) => {
            return route.name ? !route.name?.startsWith('provider-owner-repo-history-runs') : true
          },
          action: () => {
            this.$router.push(this.$generateRoute(['history', 'runs']))
          }
        },
        {
          id: 'open-repo-transforms-history',
          label: 'Transforms history',
          icon: 'history',
          keywords: ['metrics', 'stats', 'numbers', 'charts'],
          scope: 'repo',
          condition: (route) => {
            return route.name
              ? !route.name?.startsWith('provider-owner-repo-history-transforms')
              : true
          },
          action: () => {
            this.$router.push(this.$generateRoute(['history', 'transforms']))
          }
        },
        {
          id: 'open-repo-settings',
          label: 'Settings',
          icon: 'settings',
          shortkey: 'Alt+KeyS',
          keywords: ['settings', 'badges', 'audit', 'members', 'configuration'],
          scope: 'repo',
          condition: (route) => {
            return route.name ? !route.name?.startsWith('provider-owner-repo-settings') : true
          },
          action: () => {
            this.$router.push(this.$generateRoute(['settings']))
          }
        }
      ],
      'provider-owner-repo'
    )
  }

  async activateAnalysis({ showSuccessToast = false } = {}): Promise<void> {
    this.activateAnalysisLoading = true
    await this.toggleRepoActivation({
      isActivated: true,
      id: this.repository.id,
      login: this.$route.params.owner,
      provider: this.$route.params.provider
    })
    this.activateAnalysisLoading = false

    if (showSuccessToast) {
      this.$toast.success(
        `Triggered run for ${this.repository.displayName}, this might take a while.`
      )
    }

    this.refetchData()
  }
}
