
import { Component, Prop, mixins } from 'nuxt-property-decorator'

import ActiveUserMixin from '~/mixins/activeUserMixin'
import RepoListMixin from '~/mixins/repoListMixin'

/**
 * Sidebar component for repos that are in `PENDING` state post adhoc run.
 */
@Component({
  name: 'SidebarPendingAdhocRepos'
})
export default class SidebarPendingAdhocRepos extends mixins(ActiveUserMixin, RepoListMixin) {
  @Prop({ default: false })
  isCollapsed: boolean

  /**
   * Fetch hook for the component.
   *
   * @returns {Promise<void>}
   */
  async fetch(): Promise<void> {
    await this.fetchActiveUser()
    await this.fetchRepos()
  }

  /**
   * Mounted hook for the component. Binds listener for `repo-onboarding-completed` socket event.
   *
   * @returns {void}
   */
  mounted(): void {
    this.$socket.$on('repo-onboarding-completed', () => {
      this.fetchRepos(true)
    })
  }

  /**
   * BeforeDestroy hook for the componenet. Unbinds listener for `repo-onboarding-completed` socket event.
   */
  beforeDestroy() {
    this.$socket.$off('repo-onboarding-completed')
  }

  /**
   * Fetches pending repos from adhoc runs.
   *
   * @param {boolean} refetch - Refetches data from `network` if `true` instead of relying on cache.
   * @returns {Promise<void>}
   */
  async fetchRepos(refetch?: boolean): Promise<void> {
    await this.fetchPendingAdHocRepoList({
      login: this.activeOwner,
      provider: this.activeProvider,
      refetch
    })
  }

  /**
   * Builds route for a given repository object.
   *
   * @param {Record<string,string>} repo - Repository object containing `owner` and `name`.
   * @returns {string} Path to the repo.
   */
  buildRoute(repo: Record<string, unknown>): string {
    return `/${this.activeProvider}/${repo.owner ?? this.activeOwner}/${repo.name}/issues`
  }
}
