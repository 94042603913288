var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('z-menu',{attrs:{"placement":"top"},scopedSlots:_vm._u([{key:"trigger",fn:function({ toggle }){return [_c('button',{ref:"username",staticClass:"flex max-w-3xs items-center space-x-2 rounded-sm py-1 text-sm outline-none hover:bg-ink-300 focus:outline-none",class:{
        'pl-1 pr-2': !_vm.isCollapsed,
        'px-1': _vm.isCollapsed
      },attrs:{"type":"button"},on:{"click":toggle}},[_c('z-avatar',{staticClass:"flex-shrink-0 rounded-full leading-none",attrs:{"image":_vm.viewer.avatar,"fallback-image":_vm.getDefaultAvatar(_vm.viewer.email),"user-name":_vm.viewer.fullName || _vm.viewer.email,"size":"sm"}}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapsed),expression:"!isCollapsed"},{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.showTooltip ? `${_vm.viewer.fullName || _vm.viewer.email}` : '',
          delay: { show: 200, hide: 100 }
        }),expression:"{\n          content: showTooltip ? `${viewer.fullName || viewer.email}` : '',\n          delay: { show: 200, hide: 100 }\n        }"}],staticClass:"overflow-hidden overflow-ellipsis whitespace-nowrap leading-none"},[_vm._v("\n        "+_vm._s(_vm.viewer.fullName || _vm.viewer.email)+"\n      ")])],1)]}},(_vm.viewer)?{key:"body",fn:function(){return [_c('z-menu-section',{attrs:{"title":"Logged In As"}},[_c('z-menu-item',[_vm._v(_vm._s(_vm.viewer.email))])],1),_vm._v(" "),_c('z-menu-section',{attrs:{"divider":false}},[_c('z-menu-item',{attrs:{"icon":"settings","as":"nuxt-link","to":"/settings"}},[_vm._v("Settings")]),_vm._v(" "),_c('z-menu-item',{attrs:{"icon":"dashboard","as":"nuxt-link","to":"/me"}},[_vm._v("Dashboard")]),_vm._v(" "),_c('z-menu-item',{staticClass:"w-full",attrs:{"icon":"log-out","as":"button"},on:{"click":() => _vm.signOut()}},[_vm._v("Sign out")])],1)]},proxy:true}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }