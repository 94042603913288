
import { mixins, Component, Prop } from 'nuxt-property-decorator'
import ContextMixin from '~/mixins/contextMixin'
import { parseKeybinding } from 'tinykeys'

/**
 * Command Row component for the palette
 */
@Component({})
export default class CommandRow extends mixins(ContextMixin) {
  @Prop()
  label: string

  @Prop()
  labelHTML: string

  @Prop()
  icon?: string

  @Prop()
  active?: boolean

  @Prop()
  image?: string

  @Prop()
  shortkey?: string

  @Prop()
  hint?: string

  get shortkeyCombination(): string[] {
    return this.shortkey ? parseKeybinding(this.shortkey).flat(3) : []
  }

  get isMacintosh() {
    const platform = (navigator?.userAgentData as any)?.platform || navigator?.platform || 'unknown'
    return platform.toLowerCase().indexOf('mac') > -1
  }

  /**
   * Format key based on the operating system
   *
   * @param {string} key
   *
   * @return {string}
   */
  formatKey(key: string): string {
    if (key.startsWith('Key')) {
      return this.formatKey(key.replace('Key', ''))
    }

    if (key.toLowerCase() === 'alt') {
      return this.isMacintosh ? '⌥' : 'Alt'
    }
    if (['meta', 'cmd', 'ctrl'].includes(key.toLowerCase())) {
      return this.isMacintosh ? '⌘' : 'Ctrl'
    }
    return key
  }
}
