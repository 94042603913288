import { Middleware } from '@nuxt/types'
import { AuthGetterTypes } from '~/store/account/auth'
import { RepositoryDetailActions } from '~/store/repository/detail'
import { encodeRoute } from '~/utils/encodeRoute'

const hidePrivateRepos: Middleware = async ({
  error,
  redirect,
  route,
  store,
  $logErrorAndToast
}) => {
  if (!route.name?.startsWith('provider-owner-repo')) {
    return
  }
  const { provider, owner, repo } = route.params

  try {
    await store.dispatch(
      `repository/detail/${RepositoryDetailActions.FETCH_REPOSITORY_BASE_DETAILS}`,
      {
        provider,
        owner,
        name: repo
      }
    )
  } catch (e) {
    if (store.getters[`account/auth/${AuthGetterTypes.GET_LOGGED_IN}`]) {
      error({ statusCode: 404 })
      return
    }

    try {
      const next = encodeRoute(route)

      redirect(302, '/login', {
        next
      })
    } catch (error) {
      $logErrorAndToast(error as Error)
      redirect(302, '/login')
    }
  }
}

export default hidePrivateRepos
