
import { Component, mixins } from 'nuxt-property-decorator'
import PortalMixin from '@/mixins/portalMixin'

@Component({
  components: {},
  head: {
    bodyAttrs: {
      class: 'antialiased stroke-2 hide-scroll'
    }
  }
})
export default class DefaultLayout extends mixins(PortalMixin) {}
