
import Vue from 'vue'
import ZIcon from '../../ZIcon/ZIcon.vue'

interface ZMenuParentT extends Vue {
  close: () => void
}

export default Vue.extend({
  name: 'ZMenuItem',

  props: {
    as: {
      type: String,
      default: 'div'
    },
    icon: {
      type: String,
      default: undefined
    },
    iconColor: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    spacing: {
      type: String,
      default: 'p-3'
    },
    alignment: {
      type: String,
      default: 'items-center'
    },
    iconClasses: {
      type: String,
      default: ''
    },
    iconSize: {
      type: String,
      default: 'small'
    }
  },
  methods: {
    itemClicked() {
      if (this.closeOnClick) {
        const parent = this.$parent as ZMenuParentT
        parent.close()
      }
    }
  },
  computed: {
    computedIconColor(): string {
      if (this.iconColor) {
        return this.iconColor
      }
      return 'current'
    }
  }
})
