import { render, staticRenderFns } from "./SupportMenu.vue?vue&type=template&id=51cb8066&"
import script from "./SupportMenu.vue?vue&type=script&lang=ts&"
export * from "./SupportMenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarItem: require('/app/components/Layout/Sidebar/SidebarItem.vue').default,ZMenuItem: require('/app/components/zeal/ZMenu/ZMenuItem/ZMenuItem.vue').default,ZMenuSection: require('/app/components/zeal/ZMenu/ZMenuSection/ZMenuSection.vue').default,ZMenu: require('/app/components/zeal/ZMenu/ZMenu.vue').default})
