import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=0d921d04&scoped=true&"
import script from "./dashboard.vue?vue&type=script&lang=ts&"
export * from "./dashboard.vue?vue&type=script&lang=ts&"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=0d921d04&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d921d04",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainSidebar: require('/app/components/Layout/Sidebar/MainSidebar.vue').default,LoggedOutSidebar: require('/app/components/Layout/LoggedOutSidebar.vue').default,MobileNav: require('/app/components/Layout/MobileNav.vue').default,DashboardHeader: require('/app/components/Layout/DashboardHeader.vue').default,ZTab: require('/app/components/zeal/ZTab/ZTab.vue').default,Palette: require('/app/components/ToitBar/Palette.vue').default})
