
import ZDivider from '../../ZDivider/ZDivider.vue'
import Vue from 'vue'

interface ZMenuT extends Vue {
  close: () => void
}

export default Vue.extend({
  name: 'ZMenuSection',

  props: {
    title: {
      type: String
    },
    divider: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    close(): void {
      const parent = this.$parent as ZMenuT
      parent.close()
    }
  }
})
